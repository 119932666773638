import {
  deleteTeam,
  fetchTeamList,
  markAsCompleted
} from "../actions/teamList";

import React from "react";
import TeamList from "./teamListComponent";
import { connect } from "react-redux";

class TeamListContainer extends React.Component {
  render() {
    return <TeamList {...this.props} />;
  }
}

export default connect(
  ({ teamListReducer: { teamList, count, isTeamListLoading } }) => ({
    teamList,
    count,
    isTeamListLoading
  }),
  {
    fetchTeamList,
    deleteTeam,
    markAsCompleted
  }
)(TeamListContainer);
