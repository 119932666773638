import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Footer from "./footerContainer";
import Header from "./headerContainer";
import Player from "./playerContainer";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";

class Players extends Component {
  constructor(props) {
    super(props);

    this.ScrollArea = React.createRef();
  }

  validation(e) {
    e.preventDefault();
    const nextStage = this.props.stage + 1;
    this.props.history.push(`/${nextStage}`);
    this.props.incrementStage(this.props.stage);

    this.calculatePlayersCount();
  }

  calculatePlayersCount() {
    const playersNamesAges = JSON.parse(
      JSON.stringify(this.props.playersNamesAges)
    );
    var kidsCount = 0;
    var adultsCount = 0;
    var toddlersCount = 0;
    playersNamesAges.forEach((playerNameAge) => {
      switch (playerNameAge.age) {
        case 0:
          adultsCount++;
          break;
        case 1:
          kidsCount++;
          break;
        case 2:
          toddlersCount++;
          break;
        default:
          break;
      }
    });
    this.props.setKidsCount(kidsCount);
    this.props.setAdultsCount(adultsCount);
    this.props.setToddlersCount(toddlersCount);
  }

  handleAddPlayerToArray(e, t) {
    e.preventDefault();

    const playersNamesAges = JSON.parse(
      JSON.stringify(this.props.playersNamesAges)
    );

    playersNamesAges.push({ id: playersNamesAges.length, name: null, age: 0 });
    this.props.setPlayersNamesAges(playersNamesAges);
  }

  render() {
    const { t } = this.props;
    const arrayPlayer = [];

    for (let i = 0; i < this.props.playersNamesAges.length; i++) {
      arrayPlayer.push(<Player id={i} key={i} />);
    }
    return (
      <React.Fragment>
        <Header parent={this} stageTitle={t("YOUR PLAYERS")} handleClose={()=>{clearTimeout(this.t);}} />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="playersCpmt w-100 justify-content-center align-items-center flex-fill">
          <Col className="d-flex flex-column">
            <Row className="d-flex text-left">
              <Col className="col-12 col-md">
                <h3>{t("List of players")}</h3>
                <p className="active-text smaller">
                  {arrayPlayer.length} {t("added players")}
                </p>
              
                <p className="smaller notice">
                    {t("Please enter the name of each player and select the age category.")}
                </p>
              
              </Col>
              <Col className="flex-grow-0 flex-basis-init w-auto">
                {!this.props.automated ? (
                  <button
                    id="add-player-button"
                    className="active-btn-gradient small-button"
                    title={t("Add a player")}
                    type="button"
                    onClick={(e) => this.handleAddPlayerToArray(e, t)}
                  >
                    {t("Add a player")} &#10010;
                  </button>
                ) : (
                  <></>
                )}
              </Col>
              
            </Row>
            <Row className="mt-5">
              <Col className="text-center">{arrayPlayer}</Col>
            </Row>
          </Col>
        </Row>
        </ScrollContainer>
        <Footer continueCallback={(e) => this.validation(e)} />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Players));
