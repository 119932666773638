import { setFishStage, setSelectedFish } from "../actions/team";

import Fish from "./fishComponent";
import React from "react";
import { connect } from "react-redux";

class FishContainer extends React.Component {
  render() {
    return <Fish {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { selectedFish, fishStage, fishesImage } }) => ({
    selectedFish,
    fishStage,
    fishesImage
  }),
  {
    setSelectedFish,
    setFishStage
  }
)(FishContainer);
