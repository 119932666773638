import { incrementStage, setLanguage } from "../actions/team";

import React from "react";
import Welcome from "./welcomeComponent";
import { connect } from "react-redux";

class WelcomeContainer extends React.Component {
  render() {
    return <Welcome {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, fishesImage } }) => ({
    stage,
    fishesImage,
  }),
  { incrementStage, setLanguage }
)(WelcomeContainer);
