import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class fishModelViewerFinalComponent extends Component {
    render() {
        return (
                 <model-viewer
                    id="SelectedFishViewer"
                    src={this.props.src}
                    camera-controls 
                    class="fish_model_final"
                    camera-orbit="0deg 90deg 0.5m"
                    autoplay>
                </model-viewer>
        );
    }
}

export default withTranslation()(fishModelViewerFinalComponent);

