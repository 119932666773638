import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class fishModelViewerComponent extends Component {
    render() {
        return (
                 <model-viewer
                    id="FishViewer"
                    src={this.props.src}
                    camera-controls 
                    auto-rotate
                    class="fish-model-viewer"
                    autoplay>
                </model-viewer>    
        );
    }
}

export default withTranslation()(fishModelViewerComponent);

