import axios from "axios";

export const instance = axios.create({
  timeout: 120000,
  withCredentials: true
});

export const urls = {
  SERVER_API: process.env.REACT_APP_API_URL
};

export const jwtToken = function(payload) {
  const token = localStorage.getItem("authToken");
  return {
    ...payload,
    token: token
  };
};

export const login = params =>
  instance.post(urls.SERVER_API + "/login", {
    ...params
  });

export const unlogin = () => {
  localStorage.setItem("token", null);
};

export const validateToken = params =>
  instance.post(
    urls.SERVER_API + "/validate-token",
    {
      ...params
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` }
    }
  );

export const refreshToken = params =>
  instance.post(
    urls.SERVER_API + "/refresh-token",
    {
      refreshToken: localStorage.getItem("refreshToken")
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` }
    }
  );
