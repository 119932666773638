import * as service from "../api";

import { createAction, createReducer } from "redux-act";

const fetchTeamListAction = createAction();
const fetchTeamListLoadingAction = createAction();
const markAsCompletedAction = createAction();

const defaultState = {
  teamList: [],
  count: 1
};

export const deleteTeam = param => async () => {
  try {
    await service.deleteTeam(param);
  } catch (e) {
    console.log(e);
  }
};

export const fetchTeamList = (
  pageSize,
  page,
  search,
  query,
  sort,
  desc,
  date
) => async dispatch => {
  try {
    dispatch(fetchTeamListLoadingAction(true));
    const data = await service.fetchTeamList(
      pageSize,
      page,
      search,
      query,
      sort,
      desc,
      date
    );
    dispatch(fetchTeamListAction(data));
  } catch (e) {
    console.log(e);
  }
};

export const markAsCompleted = teamId => async dispatch => {
  try {
    await service.markAsCompleted(teamId);
    dispatch(markAsCompletedAction(teamId));
  } catch (e) {
    console.log(e);
  }
};

const TeamListReducer = createReducer(
  {
    [fetchTeamListAction]: (state, data) => {
      return {
        ...state,
        teamList: data.data.teams.rows,
        count: data.data.teams.count,
        isTeamListLoading: false
      };
    },
    [fetchTeamListLoadingAction]: (state, isLoading) => {
      return {
        ...state,
        isTeamListLoading: isLoading
      }
    },
    [markAsCompletedAction]: (state, data) => {
      const teams = state.teamList.map(team => {
        team.status = data === team.id ? "Completed" : team.status;
        return team;
      });
      return {
        ...state,
        teamList: teams
      };
    }
  },
  defaultState
);

export default TeamListReducer;
