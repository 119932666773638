import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Fish from "./fishContainer";
import FishInfos from "./fishInfosContainer";
import Footer from "./footerContainer";
import Header from "./headerContainer";
import brookTrout from "../assets/img/190611_OmbreFontaine-02.png";
import copperRedhorse from "../assets/img/190611_ChevalierCuivre-02.png";
import stripedBass from "../assets/img/190611_BarRaye-02.png";
import walleye from "../assets/img/190611_DoreJaune-02.png";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";

class fishesComponent extends Component {
  checkFormValidated() {
    if (this.props.selectedFish !== null) {
      this.props.setFormValidated(true);
      return true;
    } else {
      this.props.setFormValidated(false);
      return false;
    }
  }
  componentDidMount() {
    this.checkFormValidated();
  }
  componentWillUnmount() {
    this.props.setFormValidated(true);
  }
  validation(e) {
    e.preventDefault();
    if (this.checkFormValidated()) {
      const nextStage = this.props.stage + 1;
      this.props.history.push(`/${nextStage}`);
      this.props.incrementStage(this.props.stage);
    }
  }

  render() {
    const { t } = this.props;
    var species = "";

    switch (this.props.fishStage) {
      case 0:
        species = <FishInfos id={0} />;
        break;
      case 1:
        species = <FishInfos id={1} />;
        break;
      case 2:
        species = <FishInfos id={2} />;
        break;
      case 3:
        species = <FishInfos id={3} />;
        break;
      default:
        species = "";
        break;
    }
    return (
      <React.Fragment>
        <Header
          stageTitle={t("YOUR SPECIES")}
          className={species ? "blur" : ""}
          handleClose={()=>{clearTimeout(this.t);}}
        />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="fishesCmpt flex-grow-0 w-100 justify-content-center align-items-center flex-fill">
          <Col
            className={
              "fishes-container " +
              (species ? "blur" : "")
            }
          >
            <Row className="d-flex flex-column align-content-center">
              <Col lg={7} className="text-center">
                <h4>
                  {t("What species will your school of fish be made of ?")}
                </h4>
              </Col>
            </Row>
            <Row className="mt-4 pt-5">
              <Col xl={3} md={6}>
                <Fish
                  fish={copperRedhorse}
                  fishName={t("Copper redhorse")}
                  fishCharacteristic={t("THE NATIVE")}
                  fishId={0}
                />
              </Col>
              <Col xl={3} md={6}>
                <Fish
                  fish={stripedBass}
                  fishName={t("Striped bass")}
                  fishCharacteristic={t("THE SOCIABLE")}
                  fishId={1}
                />
              </Col>
              <Col xl={3} md={6}>
                <Fish
                  fish={walleye}
                  fishName={t("Walleye")}
                  fishCharacteristic={t("THE PREDATOR")}
                  fishId={2}
                />
              </Col>
              <Col xl={3} md={6}>
                <Fish
                  fish={brookTrout}
                  fishName={t("Brook trout")}
                  fishCharacteristic={t("THE POPULAR")}
                  fishId={3}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        {species}
        </ScrollContainer>
        <Footer
          continueCallback={(e) => this.validation(e)}
          className={species ? "blur" : ""}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(fishesComponent));
