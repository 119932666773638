import { incrementStage, setModalitiesAccepted } from "../actions/team";

import Modalities from "./modalitiesComponent";
import React from "react";
import { connect } from "react-redux";

class ModalitiesContainer extends React.Component {
  render() {
    return <Modalities {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      adultsCount,
      email,
      fishesName,
      kidsCount,
      language,
      selectedFish,
      stage,
      teamName,
      toddlersCount,
      fishesImage
    }
  }) => ({
    adultsCount,
    email,
    fishesName,
    kidsCount,
    language,
    selectedFish,
    stage,
    teamName,
    toddlersCount,
    fishesImage
  }),
  {
    incrementStage,
    setModalitiesAccepted
  }
)(ModalitiesContainer);
