import React, { Component } from "react";

import { Container } from "react-bootstrap";
import LoginContainer from "../adminComponents/loginContainer";

class Login extends Component {
  render() {
    return (
      <Container
        fluid={true}
        className="d-flex flex-column min-vh-100 admin-part"
      >
        <LoginContainer />
      </Container>
    );
  }
}

export default Login;
