import React, { Component } from "react";

import { Redirect } from "react-router";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import Setting from "../adminComponents/settingContainer";
import { connect } from "react-redux";
import { loggedIn } from "src/actions/login";

class SettingPage extends Component {
  loggedIn() {
    return this.props.loggedIn();
  }

  render() {
    if (!this.props.token) {
      this.loggedIn();
      return <div />;
    }
    if (this.props.token === "invalid") {
      return <Redirect to="/login" />;
    }
    return (
      <ScrollContainer
        speed={0.8}
        smoothScrolling={true}
        className={"main-container admin-part"}
        contentClassName={"container-fluid"}
        horizontal={false}
        parentClassName="vh-100"
      >
        <Setting />
      </ScrollContainer>
    );
  }
}

export default connect(
  ({ userReducer: { username, token } }) => ({
    username,
    token
  }),
  {
    loggedIn
  }
)(SettingPage);
