import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class fishModelViewerSmallComponent extends Component {
    render() {
        return (
                 <model-viewer
                    id="SmallViewer"
                    src={this.props.src}
                    class="fish-model-viewer-small"                    
                    autoplay>
                </model-viewer>
        );
    }
}

export default withTranslation()(fishModelViewerSmallComponent);

