import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import closeImg from "../assets/img/X.svg";
import infoLarge from "../assets/img/InfoLarge.png";
import rfid from "../assets/img/RFIDIcon.svg";
import warningSign from "../assets/img/WarningSign.svg";
import { withTranslation } from "react-i18next";
import bgVid from "../assets/vid/bg.mp4";

class ModalComponent extends Component {
  state = { text: "", age: 0 };

  shouldComponentUpdate(nextProps, nextState) {
    if (nextState.text !== nextProps.text) nextState.text = nextProps.text;
    if (nextState.age !== nextProps.age) nextState.age = nextProps.age;

    return true;
  }

  render() {
    const { t } = this.props;

    var disabledButton =
      typeof this.props.disabledButton !== "undefined" ||
      this.props.disabledButton != null
        ? this.props.disabledButton
        : false;

    var classNameDisabledButton = disabledButton
      ? "inactive-button"
      : "idle-button";

    let icon;
    switch (this.props.icon) {
      case "info":
        icon = (
          <Row className="modal-icon">
            <Col>
              <img src={infoLarge} alt="" />
            </Col>
          </Row>
        );
        break;
      case "warning":
        icon = (
          <Row className="modal-icon">
            <Col>
              <img src={warningSign} alt="" />
            </Col>
          </Row>
        );
        break;
      case "rfid":
        icon = (
          <Row className="modal-icon">
            <Col>
              <img src={rfid} alt="" />
            </Col>
          </Row>
        );
        break;
      default:
        break;
    }

    var buttons;
    var text;
    switch (this.props.type) {
      case "yesno":
        buttons = (
          <Row className="mt-4 text-center justify-content-start">
            <Col className="d-flex flex-grow-0 ml-3">
              <button className="idle-button" onClick={this.props.onNo}>
                Non
              </button>
            </Col>
            <Col className="flex-grow-0">
              <button className="primary-button" onClick={this.props.onYes}>
                Oui
              </button>
            </Col>
          </Row>
        );
        text = (
          <Row className="modal-text">
            <Col>{this.props.text}</Col>
          </Row>
        );
        break;
      case "input":
        buttons = (
          <Row className="mt-4">
            <Col className="ml-3">
              <button
                className={classNameDisabledButton}
                disabled={disabledButton}
                onClick={this.props.onYes}
              >
                {this.props.buttonText}
              </button>
            </Col>
          </Row>
        );
        text = (
          <Row className="modal-text">
            <Col className="mt-3">
              <input
                className="text-input"
                value={this.state.text}
                onChange={(e) => this.props.onChangeInput(e)}
              />
            </Col>
          </Row>
        );
        break;
      case "player":
        buttons = (
          <Row className="mt-4">
            <Col className="ml-3">
              <button
                className={classNameDisabledButton}
                disabled={disabledButton}
                onClick={this.props.onYes}
              >
                {this.props.buttonText}
              </button>
            </Col>
          </Row>
        );
        text = (
          <Row className="modal-text">
            <Col className="mt-3">
              <input
                className="text-input"
                value={this.state.text}
                onChange={(e) => this.props.onChangeInput(e)}
              />
            </Col>
            <Col className="flex-grow-0 flex-basis-init w-auto">
              <button
                className={
                  this.state.age == 0
                    ? "selected-button small-button w-100"
                    : "idle-button small-button w-100"
                }
                onClick={(e) => {
                  this.setState({ ...this.state, age: 0 });
                  this.props.onAgeChange(0);
                }}
              >
                {t("16 yo and +")}
              </button>
            </Col>
            <Col className="flex-grow-0 flex-basis-init w-auto">
              <button
                className={
                  this.state.age == 1
                    ? "selected-button small-button w-100"
                    : "idle-button small-button w-100"
                }
                onClick={(e) => {
                  this.setState({ ...this.state, age: 1 });
                  this.props.onAgeChange(1);
                }}
              >
                {t("4 to 15 yo")}
              </button>
            </Col>
            <Col className="flex-grow-0 flex-basis-init w-auto">
              <button
                className={
                  this.state.age == 2
                    ? "selected-button small-button w-100"
                    : "idle-button small-button w-100"
                }
                onClick={(e) => {
                  this.setState({ ...this.state, age: 2 });
                  this.props.onAgeChange(2);
                }}
              >
                {t("3 yo and -")}
              </button>
            </Col>
          </Row>
        );
        break;
      case "rfid":
        buttons = (
          <Row className="mt-4">
            <Col className="ml-3">
              <button className="idle-button" onClick={this.props.onYes}>
                {this.props.buttonText}
              </button>
            </Col>
          </Row>
        );
        text = (
          <Row className="modal-text">
            <Col className="mt-3">
              <div className="text-input">{this.props.text}</div>
            </Col>
          </Row>
        );
        break;
      default:
        break;
    }

    var displayState = this.props.display ? "block" : "none";

    return (
      <React.Fragment>
        <div
          className="modal-container with-header"
          style={{ display: displayState }}
        >
          <video className="video-bg" autoPlay loop muted>
            <source src={bgVid} type="video/mp4" />
          </video>
          <ScrollContainer
            stopScrollPropagation={true}
            smoothScrolling={true}
            className="col-lg-10 offset-lg-1 item-box item-modal col max-vh-100"
            contentClassName="item-box-content"
            speed={0.8}
            horizontal={false}
            parentClassName="align-content-center vh-100 row w-100"
          >
            <Row className="justify-content-center flex-basis-100 mb-4 stage-header ">
              <Col className="flex-grow-0 flex-basis-init stage-header-title text-center">
                <h3 className="text-uppercase text-overflow-ellipsis font-weight-bold mt-3">
                  {this.props.headerTitle}
                </h3>
              </Col>
            </Row>
            <Row className="mt-5 pt-5">
              <button
                className="modal-close-button small-button idle-button square-button"
                onClick={this.props.onClose}
              >
                <img src={closeImg} alt="" />
              </button>
              <Col className="col-10 offset-1">
                <Row>
                  <Col className="ml-3">
                    <Row className="modal-title">
                      <Col>
                        <p className="mb-0">{this.props.title}</p>
                      </Col>
                    </Row>
                    {text}
                  </Col>
                  {icon ? (
                    <Col className="d-flex flex-grow-0 flex-basis-init w-auto pt-4 mt-1">
                      {icon}
                    </Col>
                  ) : (
                    ""
                  )}
                </Row>
                {buttons}
              </Col>
            </Row>
          </ScrollContainer>
        </div>
      </React.Fragment>
    );
  }
}

export default withTranslation()(ModalComponent);
