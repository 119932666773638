import Header from "./headerComponent";
import React from "react";
import { connect } from "react-redux";
import { doUnlogin } from "src/actions/login.js";

class HeaderContainer extends React.Component {
  render() {
    return <Header {...this.props} />;
  }
}

export default connect(() => ({}), { doUnlogin })(HeaderContainer);
