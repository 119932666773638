import {
  incrementStage,
  setFishStage,
  setFormValidated,
  setSelectedFish
} from "../actions/team";

import FishInfos from "./fishInfosComponent";
import React from "react";
import { connect } from "react-redux";

class FishInfosContainer extends React.Component {
  render() {
    return <FishInfos {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      selectedFish,
      fishesNameCaps,
      fishesCharacteristic,
      fishesText,
      fishesImage,
      fishesRecord,
      fishesLongevity,
      fishesCombativity,
      fishesCombativityText,
      fishesStatus,
      fishStage,
      stage
    }
  }) => ({
    selectedFish,
    fishesNameCaps,
    fishesCharacteristic,
    fishesText,
    fishesImage,
    fishesRecord,
    fishesLongevity,
    fishesCombativity,
    fishesCombativityText,
    fishesStatus,
    fishStage,
    stage
  }),
  {
    setSelectedFish,
    setFishStage,
    setFormValidated,
    incrementStage
  }
)(FishInfosContainer);
