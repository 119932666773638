import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

class ActivityStatus extends Component {
  render() {
    return (
      <React.Fragment>
        <Row className="pb-2 border-bottom">
          <Col className="p-0">
            <h6>{this.props.name}</h6>
          </Col>
          <Col className="flex-grow-0 flex-basis-init w-auto p-0">
            {this.props.status ? (
              <h6
                className={
                  this.props.status === "Connect"
                    ? "active-text"
                    : "deactive-text"
                }
              >
                {this.props.status === "Connect" ? "Actif " : "Inactif "}
                <span
                  className={
                    "rounded-circle status-circle " +
                    (this.props.status === "Connect" ? "active" : "deactive")
                  }
                ></span>
              </h6>
            ) : (
              <h6 className="deactive-text">
                Inactif{" "}
                <span className="rounded-circle status-circle deactive"></span>
              </h6>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ActivityStatus;
