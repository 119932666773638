import React, { Component } from "react";
import logoImg from "../assets/img/Logo.png";
import bgVid from "../assets/vid/bg.mp4";
import { Col, Row } from "react-bootstrap";

export default class NoTicketPage extends Component {
  render() {
    return (
      <div>        
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Row>
          <Col className="text-center">
            <Row>
              <Col> 
                <img src={logoImg} className="logoBig" />               
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="CtnWrapperNoTicket flex-grow-1">
          <Col className="col text-center">  
            <Row>
              <Col>              
                <h2>Vous devez avoir acheté un billet pour accéder à ce site.</h2>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <a href="https://secure3.xpayrience.com/acoatica"><button
                  className="active-btn-gradient small-button" >
                  Acheter un billet
                </button>
                </a>
              </Col>
            </Row>
          </Col>
          <Col className="col text-center welcome-part2">  
            <Row>
              <Col>              
                <h2>You need a ticket to access this site.</h2>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <a href="https://secure3.xpayrience.com/acoatica_en">
                  <button
                  className="active-btn-gradient small-button">
                  Buy a ticket
                </button>
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
