import Login from "./loginComponent";
import React from "react";
import { connect } from "react-redux";
import { doLogin } from "src/actions/login.js";

class LoginContainer extends React.Component {
  render() {
    return <Login {...this.props} />;
  }
}

export default connect(
  ({ userReducer: { username, token } }) => ({
    username,
    token
  }),
  {
    doLogin
  }
)(LoginContainer);
