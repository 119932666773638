import i18n from "i18next";
import { initReactI18next } from "react-i18next";

// the translations
// (tip move them in a JSON file and import them)
const resources = {
  fr: {
    translation: {
      "16 yo and +": "16 ans et +",
      "3 yo and -": "3 ans et -",
      "4 to 15 yo": "4 à 15 ans",
      "This email address will not be used for advertising purposes. You will receive a confirmation email indicating the validity of your registration when it is completed. Following your visit, your ranking as well as a team picture will be emailed to you free of charge.":
        "Cette adresse courriel ne sera pas utilisée à des fins publicitaires. Vous recevrez un courriel de confirmation vous indiquant la validité de votre inscription lorsqu’elle sera complétée. Suite à votre visite, votre classement ainsi qu’une photo d’équipe vous seront envoyés gratuitement par courriel.",
      "added players": "joueurs ajoutés",
      "Add a player": "Ajouter un joueur",
      "Adult(s)": "Adulte(s)",
      "As a team, combine your efforts to develop a school of fish of a threatened species of Quebec.":
        "En équipe, réunissez vos efforts pour développer un banc de poissons d'une espèce menacée du Québec.",
      "BROOK TROUT": "OMBLE DE FONTAINE",
      "Back to the start": "Retour au début",
      "Better known as speckled trout, brook trout is probably the most fished fish in the lakes and rivers of Quebec. Its beautiful colored scales and delicious flesh make it a choice!":
        "Mieux connu sous le nom de truite mouchetée, l’omble de fontaine est sans doute le poisson le plus pêché dans les lacs et rivières du Québec. Ses magnifiques écailles colorées et sa délicieuse chair en font une prise de choix !",
      "Brook trout": "Omble de fontaine",
      "COPPER REDHORSE": "CHEVALIER CUIVRÉ",
      "Copper redhorse": "Chevalier cuivré",
      "Choose this specie": "Choisir cette espèce",
      "Dive into the adventure": "Plongez dans l'aventure",
      "Will you be there?":
        "Serez-vous de la partie?",
      "Fish Bank": "Banc de poissons",
      "Check-in at Parc Découverte Nature at the time indicated on the purchase confirmation to begin the Acoatica interactive survival experience.":
        "Présentez-vous à l’accueil du Parc Découverte Nature à l’heure indiquée sur la confirmation d’achat afin de débuter l’expérience de survie interactive Acoatica.",
      "I do not have an email": "Je n'ai pas de courriel",
      "I accept the terms and conditions": "J'accepte les termes et condition",
      "List of players": "Liste des joueurs",
      "Please enter the name of each player and select the age category.": "Veuillez inscrire le nom de chaque joueur et sélectionner la catégorie d’âge.",
      "Kid(s)": "Enfant(s)",
      "Moving in compact benches, striped bass likes promiscuity. Maybe a little too much ... he was the subject of an intensive commercial and sport fishery that led to his disappearance in the St. Lawrence River.":
        "Se déplaçant en bancs compacts, le bar rayé aime la promiscuité. Peut-être même un peu trop… il a fait l’objet d’une pêche commerciale et sportive intensive qui l’a mené à sa disparition dans le fleuve Saint-Laurent.",
      "Name of your team": "Nom de votre équipe",
      "Your objective will be to create the largest shoal of fish by collecting as many points as possible. Test your skills and knowledge using the various interactives of this huge video game.":
        "Votre objectif sera de créer le plus grand banc de poissons en amassant le plus de points possible. Testez vos habiletés et vos connaissances à l’aide des différents interactifs de cet immense jeu vidéo.",
      "STRIPED BASS": "BAR RAYÉ",
      "Species of fish": "Espèce de poisson",
      "Striped bass": "Bar rayé",
      "The more you increase your final score, the better the chances of survival for your species.":
        "Plus vous augmenterez votre pointage final, meilleures seront les chances de survie de votre espèce.",
      "THE POPULAR": "LE POPULAIRE",
      "THE PREDATOR": "LE PRÉDATEUR",
      "THE NATIVE": "LE NATIF",
      "THE SOCIABLE": "LE SOCIABLE",
      "Immerse yourself in the Acoatica experience. Pick up your bracelets at the counter.":
        "Immergez-vous dans l'expérience Acoatica. Récupérez vos bracelets au comptoir.",
      "The copper redhorse is the only fish found only in Quebec. However, it is endangered and, as of today, still counts only a few hundreds. Who will save the redhorse’s skin?":
        "Le chevalier cuivré est le seul poisson qui ne se trouve qu’au Québec. Pourtant, il est en voie de disparition et ne compterait plus que quelques centaines d’individus. Qui sauvera la peau du chevalier ?",
      "Toddler(s)": "Bambin(s)",
      "Walleye is a large predator that is not afraid of turbid environments and depths. It must be said that it has large silver eyes that allow him to locate his prey without being seen.":
        "Le doré jaune est un grand prédateur qui n’a pas peur des milieux turbides et des profondeurs. Il faut dire qu’il est doté de gros yeux argentés qui lui permettent de repérer ses proies sans être vu.",
      "YOUR SCHOOL OF FISH": "VOTRE BANC DE POISSONS",
      "YOUR SPECIES": "VOTRE ESPÈCE",
      "YOUR TEAM": "VOTRE ÉQUIPE",
      "YOUR PLAYERS": "VOS JOUEURS",
      "Your email address will not be used for advertising purposes.":
        "Votre adresse courriel ne sera pas utilisé à des fins de publicités.",
      "What species will your school of fish be made of ?":
        "De quelle espèce menacée sera composé votre banc de poissons ?",
      "Your school of fish": "Votre banc de poissons",
      "Your team's registration is now complete.": "L’inscription de votre équipe est maintenant complétée.",
      "The name of your team and the species of fish chosen will accompany you throughout the experience and will appear in different places on the site, visible to all. It is therefore important to choose them carefully. Please enter the name of your team":
        "Le nom de votre équipe ainsi que l’espèce du poisson choisi vous accompagneront tout au long de l’expérience et apparaitront à différents endroits sur le site, visible de tous. Il est donc important de les choisir avec soin. Veuillez inscrire le nom de votre équipe.",
      "years old": "ans",
      Cancel: "Annuler",
      Combativity: "Combativité",
      COMPLETED: "COMPLÉTÉE",
      Continue: "Continuer",
      Email: "Courriel",
      Endangered: "En voie de disparition",
      Finish: "Terminer",
      High: "Élevé",
      Longevity: "Longévité",
      Low: "Faible",
      MODALITIES: "MODALITÉS",
      Medium: "Moyenne",
      Missing: "Disparu",
      Next: "Suivant",
      None: "Aucun",
      Player: "Joueur",
      Players: "Joueurs",
      Previous: "Précédent",
      Record: "Record",
      Registration: "Inscription",
      Status: "Statut",
      SUMMARY: "SOMMAIRE",
      WALLEYE: "DORÉ JAUNE",
      Walleye: "Doré jaune",
    },
  },
};

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: "fr",

    keySeparator: false, // we do not use keys in form messages.welcome

    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });

export default i18n;
