import { combineReducers, createStore, applyMiddleware, compose } from "redux";
import userReducer from "src/actions/login";
import thunk from "redux-thunk";

const globalReducers = {
  userReducer
  // global reducers goes here
};

const store = createStore(
  combineReducers(globalReducers),
  compose(applyMiddleware(thunk))
);

store.reducers = globalReducers;

export default store;
