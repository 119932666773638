import React, { Component } from "react";

import Fishes from "./fishesContainer";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class speciesComponent extends Component {
  render() {

    return (
      <React.Fragment>
        <Fishes />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(speciesComponent));
