import "react-table/react-table.css";
import * as service from '../api'

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import ActivityStatus from "./ActivityStatusComponent";
import Header from "./headerContainer";
import Websocket from "react-websocket";
import bgVid from "../assets/vid/bg.mp4";
import trash from "../assets/img/Trash.svg";
import { withRouter } from "react-router-dom";
import Modal from "../utilitiesComponents/modalComponent";

class Setting extends Component {
  state = {
    rfid: "Veuillez tester un bracelet..."
  };
  getBack() {
    const to = "/user";
    this.props.history.push(to);
  }

  resetSallImmersive() {
    this.sendMessage({
      command: "ResetGame"
    });
  }

  handleOpen() {
    this.sendMessage({ id: "WebApp", command: "GetSalleInteractivePlayers" });
    this.sendMessage({ id: "WebApp", command: "GetOnlines" });
  }

  handleData(data) {
    data = JSON.parse(data);

    switch (data.command) {
      case "GetSalleInteractivePlayers":
        this.getSalleInteractivePlayers(data.data);
        break;
      case "Error":
        console.log(data.data);
        break;
      case "Connected":
        this.connected(data.data);
        break;
      case "Disconnected":
        this.disconnected(data.data);
        break;
      case "GetOnlines":
        this.getOnlines(data.data);
        break;
      default:
        console.log(data);
        break;
    }
  }

  sendMessage(message) {
    if (typeof message === "object") {
      message = JSON.stringify(message);
    }
    this.refWebSocket.sendMessage(message);
  }

  getSalleInteractivePlayers(playerIds) {
    this.props.fetchSallInteractivePlayersInfo(playerIds);
  }

  removeSalleInteractivePlayer(id) {
    this.sendMessage({
      id: "WebApp",
      command: "RemoveSalleInteractivePlayer",
      playerId: id
    });
  }

  getOnlines(clients) {
    let state = { ...this.state };
    console.log(clients);
    for (let client of clients) {
      state[client] = "Connect";
    }
    this.setState(state);
  }

  connected(id) {
    let state = { ...this.state };
    console.log(id + " connected");
    state[id] = "Connect";
    this.setState(state);
  }

  disconnected(id) {
    let state = { ...this.state };
    console.log(id + " disconnected");

    state[id] = "Disconnect";
    this.setState(state);
  }

  handleRfid() {
    this.setState({ ...this.state, displayRfid: true });
  }

  handleDataRFID(data) {
    this.setState({ ...this.state, rfid: data }, () => {
      this.handleLink();
    });
  }

  async handleLink() {
    if (this.state.rfid !== "Veuillez tester un bracelet...") {
      var data = await service.fetchPlayer(this.state.rfid);
      if (data.data.player !== undefined)
        this.setState({ ...this.state, rfid: data.data.player.rfid + " | " + data.data.player.name + " | " + data.data.player.team.name + " | " + data.data.player.team.registration_date.substring(0, 10) });
    }
  }

  render() {
    const sallInteractivePlayers = this.props.sallInteractivePlayers;
    let socketURL = process.env.REACT_APP_API_URL.replace("http", "ws");
    return (
      <React.Fragment>
        <Header activePage="settings" />
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Modal
          title="Numéro de bracelet"
          type="rfid"
          buttonText="Retour"
          headerTitle="TESTER BRACELET"
          text={this.state.rfid}
          icon={"rfid"}
          display={this.state.displayRfid}
          onClose={(e) =>
            this.setState({
              ...this.state,
              displayRfid: false,
            })
          }
          onYes={(e) =>
            this.setState({
              ...this.state,
              displayRfid: false,
            })
          }
        />
        <Row className="flex-fill mt-5 pt-5">
          <Col className="d-flex flex-column justify-content-between">
            <Row className="mt-5">
              <Col className="d-flex flex-column justify-content-between col-10 offset-1">
                <Row>
                  <Col>
                    <h6>PANNEAU DE CONTRÔLE DES INTERACTIFS</h6>
                  </Col>
                  <Col>
                    <button
                      className="idle-button"
                      style={{ float: "right" }}
                      onClick={(e) =>
                        this.handleRfid()
                      }>
                      Tester un bracelet
                    </button>
                  </Col>
                </Row>
                <Row className="mt-5">
                  <Col>
                    <ActivityStatus
                      name="Salle immersive"
                      status={this.state.Immersive_Room}
                    />
                    <Row className="align-items-center pt-0 idleBox p-5">
                      <Col>
                        <p>Réinitialisation du jeu complet</p>
                      </Col>
                      <Col className="flex-grow-0 flex-basis-auto">
                        <button
                          className="idle-button"
                          onClick={e => this.resetSallImmersive()}
                        >
                          Réinitialiser
                        </button>
                      </Col>
                    </Row>
                    <Row>
                      {sallInteractivePlayers.map(player => {
                        return (
                          <Col md={3} key={player.id}>
                            <div className="container-box">
                              <Row>
                                <Col>
                                  <div>{player.name}</div>
                                  <div>{player.team.name}</div>
                                </Col>
                                <Col className="d-flex justify-content-end">
                                  <img
                                    className="cursor-pointer"
                                    width="21"
                                    alt="Delete"
                                    src={trash}
                                    onClick={e =>
                                      this.removeSalleInteractivePlayer(
                                        player.id
                                      )
                                    }
                                  />
                                </Col>
                              </Row>
                            </div>
                          </Col>
                        );
                      })}
                    </Row>
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Borne Salle immersive"
                      status={this.state.Immersive_Room_WaitingQueue}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Microscope"
                      status={this.state.Microscope}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Cycle de vie"
                      status={this.state.Cycle_Of_Life}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Anatomie"
                      status={this.state.Anatomy}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Contrôle du Grand Bassin"
                      status={this.state.Home_Ceiling_Controller}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Borne Pointage"
                      status={this.state.ScoreBoard}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Borne de pêche"
                      status={this.state.Fishing_Booth}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Grand Bassin"
                      status={this.state.Home_Ceiling}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Borne à selfie"
                      status={this.state.Photobooth}
                    />
                  </Col>
                </Row>
                <Row className="mt-4">
                  <Col>
                    <ActivityStatus
                      name="Pisciculture"
                      status={this.state.Pisciculture}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        <Websocket
          url={socketURL + "/socket"}
          onMessage={this.handleData.bind(this)}
          reconnect={true}
          onOpen={this.handleOpen.bind(this)}
          ref={Websocket => {
            this.refWebSocket = Websocket;
          }}
        />
        <Websocket
          url="ws://localhost:5000/rfid/"
          onMessage={this.handleDataRFID.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Setting);
