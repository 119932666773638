import {
  postSaveInformation,
  resetStates,
  setAdultsCount,
  setEmail,
  setKidsCount,
  setLanguage,
  setPlayers,
  setSelectedFish,
  setTeamName,
  setToddlersCount,
  setFormValidated
} from "../actions/team";

import AddTeam from "./addTeamComponent";
import React from "react";
import { connect } from "react-redux";

class AddTeamContainer extends React.Component {
  render() {
    return <AddTeam {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      players,
      teamName,
      email,
      selectedFish,
      language,
      adultsCount,
      kidsCount,
      toddlersCount,
      fishOptions,
      languageOptions,
      setFormValidated
    }
  }) => ({
    players,
    teamName,
    email,
    selectedFish,
    language,
    adultsCount,
    kidsCount,
    toddlersCount,
    fishOptions,
    languageOptions,
    setFormValidated
  }),
  {
    setPlayers,
    setTeamName,
    setEmail,
    setSelectedFish,
    setLanguage,
    setAdultsCount,
    setKidsCount,
    setToddlersCount,
    postSaveInformation,
    resetStates,
    setFormValidated
  }
)(AddTeamContainer);
