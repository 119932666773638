import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "../utilitiesComponents/headerComponent";
import { Redirect } from "react-router";
import bgVid from "../assets/vid/bg.mp4";
import { withRouter } from "react-router";

class Welcome extends Component {
  doLogin(e) {
    e.preventDefault();
    this.props.doLogin({
      username: this.state !== null ? this.state.username : "",
      password: this.state !== null ? this.state.password : ""
    });
  }

  setUsername(e) {
    this.setState({ ...this.state, username: e.target.value });
  }

  setPassword(e) {
    this.setState({ ...this.state, password: e.target.value });
  }

  render() {
    if (this.props.username) {
      return <Redirect to="/user" />;
    }

    let failedLogin = <div />;
    if (this.props.token === "invalid")
      failedLogin = (
        <Row className="failed-login">
          <Col className="text-center">
            <Row className="center">
              Attention! vous devez entrer un nom d'utilisateur ou un mot de
              passe valide.
            </Row>
          </Col>
        </Row>
      );
    return (
      <React.Fragment>
        <Header
          stageTitle="CONNEXION"
          resetStates={() => this.props.history.push("/")}
          title="ADMINISTRATION"
        />
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Row className="flex-grow-1">
          <Col className="d-flex flex-column justify-content-between col-10 offset-1">
            <Row></Row>
            <Row>
              <Col className="col-md-6 offset-3">
                <form onSubmit={e => this.doLogin(e)}>
                  <Row className="mt-4">
                    <Col>
                      <p>Nom d'usager</p>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="col-md-11">
                      <input
                        className="text-input"
                        type="text"
                        id="username"
                        maxLength={100}
                        onChange={e => this.setUsername(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Row className="mt-4">
                        <Col>
                          <p>Mot de passe </p>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="col-md-11">
                          <input
                            className="text-input"
                            type="password"
                            name="pass"
                            maxLength={100}
                            onChange={e => this.setPassword(e)}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  {failedLogin}
                  <input type="submit" value="Submit" className="d-none" />
                  <Row className="flex-nowrap mt-5">
                    <Col className="flex-grow-0 flex-basis-init w-auto">
                      <button
                        label="Continue"
                        className="primary-button"
                        title="Continue"
                        type="submit"
                        onClick={e => this.doLogin(e)}
                      >
                        Connexion
                      </button>
                    </Col>
                  </Row>
                </form>
              </Col>
            </Row>
            <Row></Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(Welcome);
