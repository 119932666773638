import {
  incrementStage,
  setPlayers,
  setPlayersNamesAges,
} from "../actions/team";

import Player from "./playerComponent";
import React from "react";
import { connect } from "react-redux";

class PlayerContainer extends React.Component {
  render() {
    return <Player {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      stage,
      playersNamesAges,
      players,
      kidsCount,
      adultsCount,
      toddlersCount,
      automated,
    },
  }) => ({
    playersNamesAges,
    players,
    stage,
    kidsCount,
    adultsCount,
    toddlersCount,
    automated,
  }),
  {
    incrementStage,

    setPlayers,
    setPlayersNamesAges,
  }
)(PlayerContainer);
