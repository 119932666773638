import * as service from "../api";

import { createAction, createReducer } from "redux-act";
import Cookies from "js-cookie";

function getAgeCodeFromString(str)
{
  const ageCodes = {
    "16 ans et plus": 0,
    "ACOATICA - Billet adulte": 0,
    "ACOATICA - Adult Ticket": 0,

    "6 à 15 ans": 1,
    "ACOATICA - Billet enfant": 1,
    "ACOATICA - Child Ticket": 1,

    "5 ans et moins": 2,
    "ACOATICA - Billet Bambin": 2,
    "ACOATICA - Toddler Ticket": 2,
  };

  if (str in ageCodes)
    return ageCodes[str];
  else
    return 0; // Adult
}

const order = Cookies.get("teaminfo");
var orderInfo = {};
var playersArray = [];
var adultsCount = 0;
var kidsCount = 0;
var toddlersCount = 0;
if (order) {
  try {
    orderInfo = JSON.parse(order.substr(4, order.lastIndexOf(".") - 4));
    let idNumber = 0;
    for (let i in orderInfo.tickets) {
      if (i === "date") continue;
      const ageCode = getAgeCodeFromString(i);
      const count = orderInfo.tickets[i];
      switch (ageCode)
      {
        case 0: adultsCount += count; break;
        case 1: kidsCount += count; break;
        case 2: toddlersCount += count; break;
        default: adultsCount += count; break;
      }
      for (let j = 0; j < count; j++) {
        playersArray.push({
          id: idNumber++,
          name: null,
          age: ageCode,
        });
      }
    }
  } catch (e) {
    console.log(e);
  }
}
var url = new URL(window.location.href);
var env = url.searchParams.get("env");

const defaultState = {
  automated: orderInfo.tickets ? true : false,
  adultsCount,
  email: orderInfo.user_info ? orderInfo.user_info["email"] : "",
  fishesName: ["Copper redhorse", "Striped bass", "Walleye", "Brook trout"],
  fishesNameCaps: ["COPPER REDHORSE", "STRIPED BASS", "WALLEYE", "BROOK TROUT"],
  fishesCharacteristic: [
    "THE NATIVE",
    "THE SOCIABLE",
    "THE PREDATOR",
    "THE POPULAR",
  ],
  fishesText: [
    "The copper redhorse is the only fish found only in Quebec. However, it is endangered and, as of today, still counts only a few hundreds. Who will save the redhorse’s skin?",
    "Moving in compact benches, striped bass likes promiscuity. Maybe a little too much ... he was the subject of an intensive commercial and sport fishery that led to his disappearance in the St. Lawrence River.",
    "Walleye is a large predator that is not afraid of turbid environments and depths. It must be said that it has large silver eyes that allow him to locate his prey without being seen.",
    "Better known as speckled trout, brook trout is probably the most fished fish in the lakes and rivers of Quebec. Its beautiful colored scales and delicious flesh make it a choice!",
  ],
  fishesImage: [
    "3d/chevalier.glb",
    "3d/bar.glb",
    "3d/dorejaune.glb",
    "3d/omble.glb",
  ],
  fishesRecord: [70, 90, 104.1, 73.6],
  fishesLongevity: [30, 20, 20, 7],
  fishesCombativity: [1, 3, 2, 2],
  fishesCombativityText: ["Low", "Medium", "High"],
  fishesStatus: ["Endangered", "Missing", "None", "None"],
  kidsCount,
  language: orderInfo.user_info
    ? orderInfo.user_info["prefered_language"]
    : "fr",
  players: [],
  rfid: null,
  selectedFish: null,
  stage: 0,
  fishStage: null,
  teamName: orderInfo.user_info ? orderInfo.user_info["company"] : "",
  toddlersCount,
  playersNamesAges: playersArray.length
    ? playersArray
    : [
        {
          id: 0,
          name: null,
          age: 0,
        },
        {
          id: 1,
          name: null,
          age: 0,
        },
      ],
  formValidated: true,
  fishOptions: [
    { value: "0", label: "Chevalier cuivré" },
    { value: "1", label: "Bar rayé" },
    { value: "2", label: "Doré jaune" },
    { value: "3", label: "Omble de fontaine" },
  ],
  languageOptions: [
    { value: "fr", label: "Français" },
    { value: "en", label: "English" },
  ],
  env: env ? env : "",
  isTeamLoading: true
};

const changeNameAction = createAction();
const decrementStageAction = createAction();
const incrementStageAction = createAction();
const resetOriginalDataAction = createAction();
const resetStatesAction = createAction();
const setAdultsCountAction = createAction();
const setModalitiesAcceptedAction = createAction();
const setEmailAction = createAction();
const setFishStageAction = createAction();
const setFormValidatedAction = createAction();
const setKidsCountAction = createAction();
const setLanguageAction = createAction();
const setPlayersAction = createAction();
const setPlayersNamesAgesAction = createAction();
const setSelectedFishAction = createAction();
const setStageAction = createAction();
const setTeamAction = createAction();
const setTeamLoadingAction = createAction();
const setTeamNameAction = createAction();
const setToddlersCountAction = createAction();
const updateInformationAction = createAction();

export const fetchTeam = (params) => async (dispatch) => {
  try {
    dispatch(setTeamLoadingAction(true));
    const data = await service.fetchTeam(params);
    dispatch(setTeamAction(data));
  } catch (e) {
    console.log(e);
  }
};

export const addPlayer = (teamId, name, age) => async (dispatch) => {
  try {
    await service.addPlayer(teamId, name, age);
  } catch (e) {
    console.log(e);
  }
};

export const changeName = (id, name) => async () => {
  try {
    await service.changeName(id, name);
  } catch (e) {
    console.log(e);
  }
};

export const postSaveInformation = (params) => async (dispatch) => {
  try {
    var answer = await service.saveInformation(params);
    console.log(answer);
    return answer.data.success;
  } catch (e) {
    console.log(e);
  }
};

export const updateInformation = (team, id) => async (dispatch) => {
  try {
    await service.updateInformation(team, id);
    dispatch(updateInformationAction(team));
  } catch (e) {
    console.log(e);
  }
};

export const resetStates = () => async (dispatch) => {
  try {
    if (!Cookies.get("teaminfo")) {
      defaultState.email = "";
      defaultState.teamName = "";
      defaultState.adultsCount = 0;
      defaultState.kidsCount = 0;
      defaultState.toddlersCount = 0;
      defaultState.playersNamesAges = [
        {
          id: 0,
          name: null,
          age: 0,
        },
        {
          id: 1,
          name: null,
          age: 0,
        },
      ];
      defaultState.automated = false;
    }
    dispatch(resetStatesAction());
  } catch (e) {
    console.log(e);
  }
};

export const resetOriginalData = (params) => async (dispatch) => {
  try {
    dispatch(resetOriginalDataAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setStage = (params) => async (dispatch) => {
  try {
    dispatch(setStageAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setFishStage = (params) => async (dispatch) => {
  try {
    dispatch(setFishStageAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const incrementStage = (params) => async (dispatch) => {
  try {
    const stageIncrement = params + 1;
    if (stageIncrement > 6) dispatch(resetStatesAction());
    else {
      const stage = stageIncrement > 6 ? 0 : stageIncrement;
      dispatch(incrementStageAction(stage));
    }
  } catch (e) {
    console.log(e);
  }
};

export const decrementStage = (params) => async (dispatch) => {
  try {
    const stageIncrement = params - 1;
    if (stageIncrement < 0) dispatch(resetStatesAction());
    else {
      const stage = stageIncrement < 0 ? 0 : stageIncrement;
      dispatch(incrementStageAction(stage));
    }
  } catch (e) {
    console.log(e);
  }
};

export const setTeamName = (params) => async (dispatch) => {
  try {
    dispatch(setTeamNameAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setLanguage = (params) => async (dispatch) => {
  try {
    dispatch(setLanguageAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setEmail = (params) => async (dispatch) => {
  try {
    dispatch(setEmailAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setPlayers = (params) => async (dispatch) => {
  try {
    dispatch(setPlayersAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setAdultsCount = (params) => async (dispatch) => {
  try {
    dispatch(setAdultsCountAction(params));
  } catch (e) {
    console.log(e);
  }
};
export const setToddlersCount = (params) => async (dispatch) => {
  try {
    dispatch(setToddlersCountAction(params));
  } catch (e) {
    console.log(e);
  }
};
export const setKidsCount = (params) => async (dispatch) => {
  try {
    dispatch(setKidsCountAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setSelectedFish = (params) => async (dispatch) => {
  try {
    dispatch(setSelectedFishAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setPlayersNamesAges = (params) => async (dispatch) => {
  try {
    dispatch(setPlayersNamesAgesAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setModalitiesAccepted = (params) => async (dispatch) => {
  try {
    dispatch(setModalitiesAcceptedAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const setFormValidated = (params) => async (dispatch) => {
  try {
    dispatch(setFormValidatedAction(params));
  } catch (e) {
    console.log(e);
  }
};

export const deletePlayer = (param) => async () => {
  try {
    await service.deletePlayer(param);
  } catch (e) {
    console.log(e);
  }
};

const TeamReducer = createReducer(
  {
    [resetStatesAction]: () => {
      return defaultState;
    },
    [setStageAction]: (state, data) => {
      return {
        ...state,
        stage: data,
      };
    },
    [setFishStageAction]: (state, data) => {
      return {
        ...state,
        fishStage: data,
      };
    },
    [decrementStageAction]: (state, data) => {
      return {
        ...state,
        stage: data,
      };
    },
    [incrementStageAction]: (state, data) => {
      return {
        ...state,
        stage: data,
      };
    },
    [setTeamNameAction]: (state, data) => {
      return {
        ...state,
        teamName: data,
      };
    },
    [setEmailAction]: (state, data) => {
      return {
        ...state,
        email: data,
      };
    },
    [setAdultsCountAction]: (state, data) => {
      return {
        ...state,
        adultsCount: data,
      };
    },
    [setToddlersCountAction]: (state, data) => {
      return {
        ...state,
        toddlersCount: data,
      };
    },
    [setKidsCountAction]: (state, data) => {
      return {
        ...state,
        kidsCount: data,
      };
    },
    [setSelectedFishAction]: (state, data) => {
      return {
        ...state,
        selectedFish: data,
      };
    },
    [setLanguageAction]: (state, data) => {
      return {
        ...state,
        language: data,
      };
    },
    [setPlayersAction]: (state, data) => {
      return {
        ...state,
        players: data,
      };
    },
    [setPlayersNamesAgesAction]: (state, data) => {
      return {
        ...state,
        playersNamesAges: data,
      };
    },
    [resetOriginalDataAction]: (state, data) => {
      return {
        ...state,
        selectedFish: data.selectedFish,
        language: data.language,
        teamName: data.teamName,
        email: data.email,
        id: data.id,
        players: data.players,
        originalData: data,
      };
    },
    [updateInformationAction]: (state, data) => {
      var players = [];
      data.players.forEach((player) => {
        players.push({
          id: player.id,
          name: player.name,
          rfid: player.rfid,
          score: player.score,
          teamId: player.teamId,
          age: player.age,
        });
      });
      return {
        ...state,
        selectedFish: data.fish_type,
        language: data.language,
        teamName: data.name,
        email: data.email,
        id: data.id,
        players: players,
        originalData: {
          selectedFish: data.fish_type,
          language: data.language,
          teamName: data.name,
          email: data.email,
          id: data.id,
          players: players,
        },
      };
    },
    [setTeamAction]: (state, data) => {
      var players = [];
      data.data.team.players.forEach((player) => {
        players.push({
          id: player.id,
          name: player.name,
          rfid: player.rfid,
          score: player.score,
          teamId: player.teamId,
          age: player.age,
        });
      });
      return {
        ...state,
        selectedFish: data.data.team.fish_type,
        language: data.data.team.language,
        teamName: data.data.team.name,
        email: data.data.team.email,
        id: data.data.team.id,
        toddlersCount: data.data.team.toddlers_count,
        kidsCount: data.data.team.kids_count,
        adultsCount: data.data.team.adults_count,
        players: players,
        originalData: {
          selectedFish: data.data.team.fish_type,
          language: data.data.team.language,
          teamName: data.data.team.name,
          email: data.data.team.email,
          id: data.data.team.id,
          players: players,
        },
        isTeamLoading: false
      };
    },
    [setTeamLoadingAction]: (state, isLoading) => {
      return {
        ...state,
        isTeamLoading: isLoading
      }
    },
    [changeNameAction]: (state, data) => {
      return {
        ...state,
        name: data,
      };
    },
    [setFormValidatedAction]: (state, data) => {
      return {
        ...state,
        formValidated: data,
      };
    },
  },
  defaultState
);

export default TeamReducer;
