import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import "./App.scss";

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AppLayout from "src/layouts";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/" component={AppLayout} />
      </Switch>
    );
  }
}

export default App;