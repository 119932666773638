import "react-day-picker/lib/style.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import DayPicker from "react-day-picker";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Sidebar extends Component {
  state = {
    selectedDay: new Date(),
  };

  constructor(props){
    super(props);

    // Localize for french calendar
    this.MONTHS = [
      'Janvier',
      'Février',
      'Mars',
      'Avril',
      'Mai',
      'Juin',
      'Juillet',
      'Aout',
      'Septembre',
      'Octobre',
      'Novembre',
      'Décembre',
    ];
    this.WEEKDAYS_LONG = [
      'Dimanche',
      'Lundi',
      'Mardi',
      'Mercredi',
      'Jeudi',
      'Vendredi',
      'Samedi',
    ];
    this.WEEKDAYS_SHORT = ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'];
  }

  getFilterSelected(filterSelected) {
    switch (filterSelected) {
      case 0:
        return "incomplete";
      case 1:
        return "completed";
      default:
        return null;
    }
  }

  handleFilters(filter) {
    if (filter === this.state.filter) {
      filter = null;
    }
    this.setState({ ...this.state, filter: filter });
    this.props.handleFilters(filter);
  }

  handleQuery(e) {
    this.setState({ ...this.state, query: e.target.value });
    this.props.handleQuery(e.target.value);
  }

  handleDayClick(day) {
    if (
      this.state.selectedDay &&
      day.toISOString() === this.state.selectedDay.toISOString()
    ) {
      this.setState({ selectedDay: new Date() });
      this.props.handleDate(new Date().toISOString().split("T")[0]);
    } else {
      this.setState({ selectedDay: day });
      this.props.handleDate(day.toISOString().split("T")[0]);
    }
  }

  reset() {
    this.seatchBox.value = "";
    this.setState({
      query: null,
      filter: null,
      selectedDay: new Date(),
    });
    this.props.reset();
  }

  render() {
    return (
      <React.Fragment>
        <div className="flex-fill sidebar-container col-sm-5 col-md-4 col-lg-3 mr-5">
          <div className="sidebar col pb-4">
            <Row className="mt-4">
              <Col>
                <h6 className="font-weight-bold">Filtres</h6>
              </Col>
            </Row>
            <Row className="mt-2">
              <Col>
                <p className="smaller active-text xxs-size">
                  Nom d'équipe, courriel, joueur, # de commande
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <input
                  className="text-input admin-input"
                  type="text"
                  id="filter"
                  ref={(x) => (this.seatchBox = x)}
                  onChange={(e) => this.handleQuery(e)}
                />
              </Col>
            </Row>
            <Row className="mt-3">
              <Col>
                <p className="smaller active-text xxs-size">
                  Équipes prêtes à jouer
                </p>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="custom-control custom-checkbox style-2 admin-checkbox">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck2"
                    checked={this.state.filter === "completed"}
                    onChange={(e) => this.handleFilters("completed")}
                  />
                  <label className="custom-control-label" htmlFor="customCheck2">
                    Prêt à jouer
                  </label>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="custom-control custom-checkbox style-2">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="customCheck1"
                    checked={this.state.filter === "incomplete"}
                    onChange={(e) => this.handleFilters("incomplete")}
                  />
                  <label className="custom-control-label" htmlFor="customCheck1">
                    Non disposé à jouer
                  </label>
                </div>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <p className="smaller active-text xxs-size" style={{ marginBottom: 0 }}>Date de visite</p>
              </Col>
            </Row>
            <Row style={{ marginTop: 0, marginBottom: 0 }}>
              <Col className="">
                <DayPicker
                  locale="fr"
                  months={this.MONTHS}
                  weekdaysLong={this.WEEKDAYS_LONG}
                  weekdaysShort={this.WEEKDAYS_SHORT}
                  firstDayOfWeek={1}
                  onDayClick={(day) => {
                    this.handleDayClick(day);
                  }}
                  selectedDays={this.state.selectedDay}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <button
                  className="idle-button small-button ml-3"
                  onClick={(e) => this.reset()}
                  style={{ margin: 0 }}
                >
                  Réinitialiser
                </button>
                <button
                  className="primary-button small-button ml-3"
                  onClick={(e) => this.props.fetchData()}
                >
                  &nbsp;Appliquer&nbsp;
                </button>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
export default withRouter(withTranslation()(Sidebar));
