import React from "react";
import Setting from "./settingComponent";
import { connect } from "react-redux";
import { fetchSallInteractivePlayersInfo } from "../actions/setting";

class SettingContainer extends React.Component {
  render() {
    return <Setting {...this.props} />;
  }
}

export default connect(
  ({ settingReducer: { sallInteractivePlayers } }) => ({
    sallInteractivePlayers
  }),
  {
    fetchSallInteractivePlayersInfo
  }
)(SettingContainer);
