import React, { Component } from "react";

import MainContainer from "../mainComponents/mainContainer";
import { connect } from "react-redux";
import 'focus-visible';

class Main extends Component {
  render() {
    return (
        <MainContainer />
    );
  }
}

export default connect(
  ({ teamReducer: { fishStage, stage } }) => ({
    fishStage, stage
  }),
  {}
)(Main);
