import "react-table/react-table.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "../utilitiesComponents/headerComponent";
import Modal from "../utilitiesComponents/modalComponent";
import ReactTable from "react-table";
import Sidebar from "./sidebarContainer";
import TableSpinner from "../utilitiesComponents/tableSpinnerComponent";
import Websocket from "react-websocket";
import bgVid from "../assets/vid/bg.mp4";
import editButton from "../assets/img/EditButton.png";
import trash from "../assets/img/Trash.svg";
import { withRouter } from "react-router-dom";

class Team extends Component {
  state = {
    RFIDs: [],
    currentNames: [],
    displayAddPlayer: false,
    displayRename: false,
    displayRfid: false,
    playerId: -1,
    disabledRenameButton: true,
    disabledAddButton: true,
    playerNewName: "",
    playerNewAge: 0,
    rfid: "---",
    playerName: "",
    playerAge: 0,
  };

  constructor(props) {
    super(props);

    this.handleTeam();
  }

  static getDerivedStateFromProps(props, state) {
    state.playCount = props.toddlersCount + props.kidsCount + props.adultsCount;
    return state;
  }

  async handleTeam() {
    await this.props.fetchTeam(this.props.id);
    let players = JSON.parse(JSON.stringify(this.props.players));
    let rfids = JSON.parse(JSON.stringify(this.state.RFIDs));
    let names = JSON.parse(JSON.stringify(this.state.currentNames));

    players.map((player) => {
      var index = rfids.findIndex((rfid) => rfid.id === player.id);
      var index2 = names.findIndex((name) => name.id === player.id);

      player.rfid = index < 0 ? player.rfid : rfids[index].rfid;
      player.name = index2 < 0 ? player.name : names[index2].name;
      player.age = index2 < 0 ? player.age : names[index2].age;

      if (index < 0) rfids.push({ id: player.id, rfid: player.rfid });

      if (index2 < 0)
        names.push({ id: player.id, name: player.name, age: player.age });

      return player;
    });

    this.setState({ ...this.state, currentNames: names, RFIDs: rfids });
    this.props.setPlayers(players);
  }

  handleModifyName(id) {
    const names = JSON.parse(JSON.stringify(this.state.currentNames));
    var index = names.findIndex((name) => name.id === id);
    var name = index > -1 ? names[index].name : "";
    var age = index > -1 ? names[index].age : 0;

    this.setState({
      ...this.state,
      displayRename: true,
      disabledRenameButton: false,
      playerId: id,
      playerName: name,
      playerAge: age,
    });
  }

  saveName() {
    var names = JSON.parse(JSON.stringify(this.state.currentNames));
    this.setState({ ...this.state, displayRename: false, names: names }, () => {
      var players = JSON.parse(JSON.stringify(this.props.players));
      players.map((player) => {
        var index = names.findIndex((name) => name.id === player.id);
        player.name = index < 0 ? player.name : names[index].name;
        player.age = index < 0 ? player.age : names[index].age;
        return player;
      });
      this.props.setPlayers(players);
    });
  }

  setName(e) {
    var newName = e.target.value;
    if (newName) {
      var names = JSON.parse(JSON.stringify(this.state.currentNames));
      const idName = {
        id: this.state.playerId,
        age: this.state.playerAge,
        name: newName,
      };
      const index = names.findIndex((name) => name.id === this.state.playerId);
      if (index > -1) {
        names[index] = idName;
      } else {
        names.push(idName);
      }
      this.setState({
        ...this.state,
        disabledRenameButton: false,
        currentNames: names,
        playerName: newName,
      });
    } else {
      this.setState({
        ...this.state,
        disabledRenameButton: true,
        playerName: newName,
      });
    }
  }

  setAge(age) {
    var newAge = age;
    var names = JSON.parse(JSON.stringify(this.state.currentNames));
    const idName = {
      id: this.state.playerId,
      name: this.state.playerName,
      age: newAge,
    };
    const index = names.findIndex((name) => name.id === this.state.playerId);
    if (index > -1) {
      names[index] = idName;
    } else {
      names.push(idName);
    }
    this.setState({
      ...this.state,
      disabledRenameButton: false,
      currentNames: names,
      playerAge: newAge,
    });
  }

  handleData(data) {
    this.setState({ ...this.state, rfid: data, displayRfid: false }, () => {
      this.handleLink();
    });
  }

  handleRfid(id) {
    this.setState({ ...this.state, displayRfid: true, playerId: id });
  }

  handleLink() {
    var that = this;
    if (this.state.rfid !== "---" && this.state.playerId !== -1) {
      const rfids = JSON.parse(JSON.stringify(this.state.RFIDs));

      const idRfid = { id: this.state.playerId, rfid: this.state.rfid };
      const index = rfids.findIndex((rfid) => rfid.id === this.state.playerId);
      const index2 = rfids.findIndex((rfid) => rfid.rfid === this.state.rfid);

      if (index > -1 && index2 > -1) {
        rfids.splice(index2, 1);
        rfids[index] = idRfid;
      }
      if (index > -1 && index2 <= -1) {
        rfids[index] = idRfid;
      }
      if (index <= -1 && index2 > -1) {
        rfids.splice(index2, 1);
        rfids.push(idRfid);
      }
      if (index <= -1 && index2 <= -1) {
        rfids.push(idRfid);
      }

      this.setState(
        { ...this.state, rfid: "---", RFIDs: rfids, displayRfid: false },
        () => {
          var players = JSON.parse(JSON.stringify(this.props.players));
          players.map((player) => {
            var index = rfids.findIndex((rfids) => rfids.id === player.id);
            player.rfid = index < 0 ? null : rfids[index].rfid;
            return player;
          });

          this.props.setPlayers(players);
          setTimeout(() => {
            that.save();
          }, 1);
        }
      );
    }
  }

  async handleDeletePlayer(e, id) {
    e.preventDefault();
    await this.props.deletePlayer(id);
    this.handleTeam();
  }

  timer = "";
  isClicked = false;
  handleClick() {
    this.isClicked = !this.isClicked;
    setTimeout(() => {
      if (this.isClicked) {
        this.isClicked = false;
      }
    }, 300);
  }

  bodyComponent(tableState) {
    return <div className="rt-tbody"> {tableState.children} </div>;
  }

  handleAddPlayer() {
    var player = this.props.language === "fr" ? "Joueur " : "Player ";
    this.setState({
      ...this.state,
      playerNewName: player + (this.state.playCount + 1),
      disabledAddButton: false,
      displayAddPlayer: true,
    });
  }

  setNewName(e) {
    var playerNewName = e.target.value;
    if (playerNewName)
      this.setState({
        ...this.state,
        playerNewName: playerNewName,
        disabledAddButton: false,
      });
    else {
      this.setState({
        ...this.state,
        playerNewName: playerNewName,
        disabledAddButton: true,
      });
    }
  }

  setNewAge(age) {
    this.setState({
      ...this.state,
      playerNewAge: age,
      disabledAddButton: false,
    });
  }

  async addPlayer() {
    this.setState({
      ...this.state,
      displayAddPlayer: false,
    });
    await this.props.addPlayer(
      this.props.id,
      this.state.playerNewName,
      this.state.playerNewAge
    );
    this.handleTeam();
  }

  save() {
    var players = this.props.players;
    var status = "completed";
    players.forEach((player) => {
      if (player.rfid === null || player.rfid === "") status = "incomplete";
    });

    this.props.updateInformation(
      {
        email: this.props.email,
        name: this.props.teamName,
        fish_type: this.props.selectedFish,
        language: this.props.language,
        players: this.props.players,
        status: status,
      },
      this.props.match.params.id
    );
  }

  handleGoBack() {
    this.save();
    var to = "/user";
    this.props.history.push(to);
  }

  render() {
    var data = JSON.parse(JSON.stringify(this.props.players));
    data = data.map((row) => {
      row.ImgModify = editButton;
      row.rfid = row.rfid === null ? "Associer bracelet" : row.rfid;
      return row;
    });

    var self = this;
    return (
      <React.Fragment>
        <Header
          stageTitle={"ÉQUIPE " + this.props.teamName}
          resetStates={() => ""}
          title="ADMINISTRATION"
          back={() => this.handleGoBack()}
        />
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Modal
          title="Nom du joueur"
          type="player"
          buttonText="Sauvegarder"
          headerTitle="Renommer"
          text={this.state.playerName}
          age={this.state.playerAge}
          onChangeInput={(e) => this.setName(e)}
          onAgeChange={(age) => this.setAge(age)}
          display={this.state.displayRename}
          onClose={(e) =>
            this.setState({
              ...this.state,
              displayRename: false,
            })
          }
          disabledButton={this.state.disabledRenameButton}
          onYes={(e) => this.saveName()}
        />
        <Modal
          title="Numéro de bracelet"
          type="rfid"
          buttonText="Associer"
          headerTitle="ASSOCIER BRACELET"
          text={this.state.rfid}
          icon={"rfid"}
          display={this.state.displayRfid}
          onClose={(e) =>
            this.setState({
              ...this.state,
              displayRfid: false,
            })
          }
          onYes={(e) => this.handleLink(self)}
        />
        <Modal
          title="Nom du joueur"
          type="player"
          buttonText="Ajouter"
          headerTitle="NOUVEAU JOUEUR"
          text={this.state.playerNewName}
          age={this.state.playerNewAge}
          onChangeInput={(e) => this.setNewName(e)}
          onAgeChange={(age) => this.setNewAge(age)}
          display={this.state.displayAddPlayer}
          onClose={(e) =>
            this.setState({
              ...this.state,
              displayAddPlayer: false,
            })
          }
          disabledButton={this.state.disabledAddButton}
          onYes={(e) => this.addPlayer()}
        />
        <Row className="flex-fill p-5 ">
          <Col className="d-flex col-sm-7 col-md mt-5 pr-5 pt-2">
            <Row className="flex-grow-1 mt-5">
              <Col className="d-flex flex-column justify-content-start col-12">
                <Row className="mb-5">
                  <Col>
                    <Row className="d-flex">
                      <Col className="flex-grow-0 flex-basis-init w-auto align-self-center">
                        <h6>Liste des joueurs</h6>
                        <p className="smaller active-text xxs-size">
                          {this.state.playCount} joueurs
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end">
                        <button
                          className="idle-button small-button"
                          onClick={(e) => this.handleAddPlayer()}
                        >
                          Ajouter un joueur
                        </button>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <ReactTable
                  TbodyComponent={this.bodyComponent.bind(this)}
                  pages={Math.ceil(this.props.count / 20)}
                  data={data}
                  loading={this.props.isTeamLoading}
                  LoadingComponent={TableSpinner}
                  columns={[
                    {
                      Header: "Bracelet associé",
                      id: "state",
                      width: 200,
                      Cell: (row) =>
                        row.original.rfid === null ||
                        row.original.rfid === undefined ||
                        row.original.rfid === "Associer bracelet" ? (
                          <React.Fragment></React.Fragment>
                        ) : (
                          <Row className="d-flex col-sm-12 justify-self-center">
                            <Col>
                              <i className="check active-text "></i>
                            </Col>
                          </Row>
                        ),
                    },
                    {
                      Header: "Nom du joueur",
                      id: "name",
                      width: 450,
                      Cell: (row) => {
                        return (
                          <Row>
                            <Col
                              className="cursor-pointer"
                              onClick={(e) =>
                                this.handleModifyName(row.original.id)
                              }
                            >
                              {row.original.name}
                            </Col>
                          </Row>
                        );
                      },
                    },
                    {
                      Header: "Numéro de bracelet",
                      accessor: "rfid",
                      width: 250,
                      Cell: (row) => {
                        return (
                          <React.Fragment>
                            <Row className="flex-grow-1">
                              <Col
                                className="align-self-center cursor-pointer"
                                onClick={(e) =>
                                  this.handleRfid(row.original.id)
                                }
                              >
                                <p className="xxs-size font-weight-normal idleBox text-center p-1 mb-0">
                                  {row.original.rfid}
                                </p>
                              </Col>
                            </Row>
                          </React.Fragment>
                        );
                      },
                    },
                    {
                      Header: "",
                      id: "delete",
                      Cell: (row) => {
                        return (
                          <Row className="flex-grow-1">
                            <Col className="d-flex justify-content-end cursor-pointer mr-4">
                              <img
                                width={21.85}
                                alt=""
                                src={trash}
                                onClick={(e) =>
                                  this.handleDeletePlayer(e, row.original.id)
                                }
                              />
                            </Col>
                          </Row>
                        );
                      },
                    },
                  ]}
                  defaultPageSize={5}
                  manual
                  onFetchData={(state) => {
                    data.sort(function (a, b) {
                      if (state !== null && state.sorted.length >= 1) {
                        var id = state.sorted[0].id;
                        if (id === "state") id = "rfid";

                        if (a[id].toLowerCase() > b[id].toLowerCase()) {
                          return state.sorted[0].desc ? 1 : -1;
                        }
                        if (b[id].toLowerCase() > a[id].toLowerCase()) {
                          return state.sorted[0].desc ? -1 : 1;
                        }
                      }
                      return 0;
                    });
                    data.map((player) => {
                      player.rfid =
                        player.rfid === "Associer bracelet"
                          ? null
                          : player.rfid;
                      return player;
                    });
                    this.props.setPlayers(data);
                  }}
                  showPagination={false}
                  getTrProps={(state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.setState({
                            selected: rowInfo.index,
                          });
                        },
                        className:
                          rowInfo.index === this.state.selected
                            ? "selected-row"
                            : "",
                      };
                    } else {
                      return {};
                    }
                  }}
                ></ReactTable>
              </Col>
            </Row>
          </Col>
          <Col className="d-flex col-sm-5 col-md-4 col-lg-3 mt-5">
            <Sidebar id={this.props.id} />
          </Col>
        </Row>
        <Websocket
          url="ws://localhost:5000/rfid/"
          onMessage={this.handleData.bind(this)}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(Team);
