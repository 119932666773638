import { Spinner } from "react-bootstrap";
import React, { Component, Fragment } from "react";

class TableSpinner extends Component {

  render() {
   return (
     <Fragment>
       {
         this.props.loading
         ? <div
             style={{
               position: "absolute",
               height: "100%",
               width: "100%",
               marginTop: "2em",
               background: "#183B28"
           }}>
             <div style={{ position:"absolute", top: "50%", left: "50%" }}>
               <Spinner animation="border" role="status"/>
             </div>
           </div>
         : null
       }
     </Fragment>
   )
  }
 }

export default TableSpinner;
