import { Col, Dropdown, Row } from "react-bootstrap";
import React, { Component } from "react";

import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import Spinner from "react-bootstrap/Spinner";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Sidebar extends Component {
  state = {
    players: [{}],
    loading: true,
    name: "",
    playerId: 0,
    teamName: ""
  };

  setTeamName = e => {
    this.props.setTeamName(e.target.value);
  };
  setEmail(e) {
    this.props.setEmail(e.target.value);
  }
  setSelectedFish = e => {
    var selectedFish = parseInt(e);
    this.props.setSelectedFish(selectedFish);
  };
  setLanguage = e => {
    this.props.setLanguage(e);
  };

  render() {
    var selectedFish =
      this.props.selectedFish === null ||
      this.props.selectedFish === undefined ||
      this.props.selectedFish > 3 ||
      this.props.selectedFish < 0
        ? 0
        : this.props.selectedFish;
    var dropdownFishOptions = [];
    this.props.fishOptions.forEach(fish => {
      var cls = "";
      if (parseInt(fish.value) === selectedFish) {
        cls = "active";
      }
      dropdownFishOptions.push(
        <Dropdown.Item className={cls} eventKey={fish.value} key={fish.value}>
          {fish.label}
        </Dropdown.Item>
      );
    });

    var dropdownLanguageOptions = [];
    this.props.languageOptions.forEach(language => {
      var cls = "";
      if (language.value === this.props.language) {
        cls = "active";
      }
      dropdownLanguageOptions.push(
        <Dropdown.Item
          className={cls}
          eventKey={language.value}
          key={language.value}
        >
          {language.label}
        </Dropdown.Item>
      );
    });

    var index = this.props.languageOptions.findIndex(
      language => language.value === this.props.language
    );
    var selectedLanguage = index > -1 ? index : 0;

    return (
      <React.Fragment>
        <Row className="flex-fill sidebar-container">
          <Col className="sidebar">
            <ScrollContainer
              speed={0.8}
              smoothScrolling={true}
              className={"h-100"}
              contentClassName="h-100"
              horizontal={false}
              parentClassName="h-100"
            >
              <Row className="mt-4">
                <Col>
                  <h6 className="font-weight-bold">Informations</h6>
                </Col>
              </Row>
              <Row className="mt-4">
                <Col>
                  <p className="smaller active-text xxs-size">Nom de l'équipe</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.props.isTeamLoading
                      ? <Spinner animation="border" role="status"/>
                      : <input
                          className="text-input admin-input"
                          type="text"
                          id="teamName"
                          onChange={e => this.setTeamName(e)}
                          defaultValue={this.props.teamName}
                        />
                  }
                </Col>
              </Row>
              <Row className="mt-4 pt-3">
                <Col>
                  <p className="smaller active-text xxs-size">
                    Courriel associé à l'équipe
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.props.isTeamLoading
                      ? <Spinner animation="border" role="status"/>
                      : <input
                          className="text-input admin-input"
                          type="text"
                          id="email"
                          onChange={e => this.setEmail(e)}
                          defaultValue={this.props.email}
                        />
                  }
                </Col>
              </Row>
              <Row className="mt-4 pt-3">
                <Col>
                  <p className="smaller active-text xxs-size">Langue</p>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.props.isTeamLoading
                      ? <Spinner animation="border" role="status"/>
                      :  <Dropdown onSelect={e => this.setLanguage(e)}>
                          <Dropdown.Toggle
                            variant
                            className="dropdown-control admin-dropdown"
                          >
                            {this.props.languageOptions[selectedLanguage].label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownLanguageOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                  }
                </Col>
              </Row>
              <Row className="mt-4 pt-3">
                <Col>
                  <p className="smaller active-text xxs-size">
                    Espèce de poisson
                  </p>
                </Col>
              </Row>
              <Row>
                <Col>
                  {
                    this.props.isTeamLoading
                      ? <Spinner animation="border" role="status"/>
                      : <Dropdown onSelect={e => this.setSelectedFish(e)}>
                          <Dropdown.Toggle
                            variant
                            className="dropdown-control  admin-dropdown"
                          >
                            {this.props.fishOptions[selectedFish].label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownFishOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                  }
                </Col>
              </Row>
            </ScrollContainer>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Sidebar));
