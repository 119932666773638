import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Footer from "./footerContainer";
import Header from "./headerContainer";
import fishesGroupModel from "../assets/bancdepoissons.glb";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import FishViewerComponent from "./fishGroupModelViewerComponent";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";

class Mission extends Component {
  validation(e) {
    e.preventDefault();
    const nextStage = this.props.stage + 1;
    this.props.history.push(`/${nextStage}`);
    this.props.incrementStage(this.props.stage);
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Header parent={this} stageTitle={t("MISSION")} handleClose={()=>{clearTimeout(this.t);}} />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="w-100 justify-content-center align-items-center flex-fill">
          <Col className="text-center p-0 p-sm-5 col-12 col-md-6">

          <FishViewerComponent
                    src={fishesGroupModel}
                  />
          </Col>
          <Col className="">
            <Row>
              <Col>
                <h4>
                  {t(
                    "Your objective will be to create the largest shoal of fish by collecting as many points as possible. Test your skills and knowledge using the various interactives of this huge video game."
                  )}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h4>
                  {t(
                    "The more you increase your final score, the better the chances of survival for your species."
                  )}
                </h4>
              </Col>
            </Row>
            <Row>
              <Col>
                <h3>{t("Will you be there?")}</h3>
              </Col>
            </Row>
          </Col>
        </Row>
        </ScrollContainer>
        <Footer continueCallback={(e) => this.validation(e)} />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Mission));
