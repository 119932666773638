import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "./headerContainer";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";

class WelcomeComponent extends Component {
  constructor(props)
  {
    super(props);
    // Internet Explorer 6-11
    this.isIE = /*@cc_on!@*/false || !!document.documentMode;
  }

  handleLanguage = (language) => {
    const { i18n } = this.props;
    i18n.changeLanguage(language);
    this.props.setLanguage(language);
    this.handleContinue();
  };

  handleContinue = () => {
    this.props.history.push(`/${1}`);
    this.props.incrementStage(this.props.stage);
  };

  render() {
    return (
      <React.Fragment>
        {!this.isIE && 
        <Header />
         }
         {this.isIE && 
          <div style={{margin:"auto", fontSize:"30px", textAlign:"center"}}>
            <Row style={{display:"block"}}>Navigateur non supporté. Veuillez utiliser un navigateur plus récent.</Row>
            <Row style={{display:"block"}}>Browser not supported. Please use a more recent browser.</Row>
            </div>
         }        
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >          
        {!this.isIE && 
        <Row className="w-100 justify-content-center align-items-center flex-fill">
          <Col className="text-center p-0 p-sm-5 col-12 col-md-6">  
            <Row>
              <Col>
                <h1>PLONGEZ DANS L’AVENTURE</h1>
              </Col>
            </Row>
            <Row>
              <Col>
              
                <h5>
                  Contribuez à sauver une espèce de poisson menacée en relevant les différents défis d’Acoatica.
                </h5>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <button
                  className="active-btn-gradient small-button"
                  onClick={() => this.handleLanguage("fr")}
                >
                  Inscrire mon équipe
                </button>
              </Col>
            </Row>
          </Col>
          <Col className="text-center p-0 p-sm-5 col-12 col-md-6 welcome-part2">
            <Row>
              <Col>
                <h1>DIVE INTO<br />THE ADVENTURE</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <h5>
                  Help save an endangered species of fish by meeting the various challenges of Acoatica. 
                </h5>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col>
                <button
                  className="active-btn-gradient small-button"
                  onClick={() => this.handleLanguage("en")}
                >
                  Register my team
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
        }
        </ScrollContainer>
      </React.Fragment>     
    );
  }
}
export default withRouter(withTranslation()(WelcomeComponent));
