import React, { Component } from "react";
import { withTranslation } from "react-i18next";

class fishGroupModelViewerComponent extends Component {
    render() {
        return (
                 <model-viewer
                    id="SelectedFishGroupViewer"
                    src={this.props.src}
                    //camera-controls
                    class="fish_group_model"
                    camera-target='-0.1m 0.1m 0m'
                    camera-orbit="70deg 90deg 1.1m"
                    autoplay>
                </model-viewer>
        );
    }
}

export default withTranslation()(fishGroupModelViewerComponent);

