import {
  incrementStage,
  setFormValidated,
  setSelectedFish
} from "../actions/team";

import Fishes from "./fishesComponent";
import React from "react";
import { connect } from "react-redux";

class FishesContainer extends React.Component {
  render() {
    return <Fishes {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { selectedFish, stage, formValidated, fishStage } }) => ({
    selectedFish,
    stage,
    formValidated,
    fishStage
  }),
  {
    setSelectedFish,
    incrementStage,
    setFormValidated
  }
)(FishesContainer);
