import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import trash from "../assets/img/Trash.svg";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class Player extends Component {
  constructor(props) {
    super(props);

    const index = this.props.id;
    this.state = {
      buttonDown: this.props.playersNamesAges[index].age,
      name: this.props.playersNamesAges[index].name
        ? this.props.playersNamesAges[index].name
        : "",
    };
  }

  setPlayerNameAge() {
    const playersNamesAges = JSON.parse(
      JSON.stringify(this.props.playersNamesAges)
    );

    const index = this.getIndex(playersNamesAges);

    const idNameAge = {
      id: this.props.id,
      name: this.state.name,
      age: this.state.buttonDown,
    };

    if (index > -1) {
      playersNamesAges[index] = idNameAge;
    } else {
      playersNamesAges.push(idNameAge);
    }
    this.props.setPlayersNamesAges(playersNamesAges);
  }

  getIndex(playersNamesAges) {
    return playersNamesAges.findIndex((name) => name.id === this.props.id);
  }

  handleAge(e, toggle) {
    e.preventDefault();
    this.setState({ ...this.state, age: toggle });
    const playersNamesAges = JSON.parse(
      JSON.stringify(this.props.playersNamesAges)
    );
    playersNamesAges[this.props.id].age = toggle;
    this.props.setPlayersNamesAges(playersNamesAges);
  }

  handleName(e) {
    this.setState({ ...this.state, name: e.target.value });
    const playersNamesAges = JSON.parse(
      JSON.stringify(this.props.playersNamesAges)
    );
    playersNamesAges[this.props.id].name = e.target.value;
    this.props.setPlayersNamesAges(playersNamesAges);
  }

  static getDerivedStateFromProps(props, current_state) {
    if (current_state.name !== props.playersNamesAges[props.id].name) {
      return {
        name: props.playersNamesAges[props.id].name
          ? props.playersNamesAges[props.id].name
          : "",
        age: props.playersNamesAges[props.id].age
          ? props.playersNamesAges[props.id].age
          : 0,
      };
    }
    return null;
  }

  render() {
    const { t } = this.props;
    const butn = ["", "", ""];

    for (let index = 0; index < butn.length; index++) {
      butn[index] =
        index === this.props.playersNamesAges[this.props.id].age
          ? "selected-button"
          : "idle-button";
    }
    return (
      <React.Fragment>
        <Row className="flex-grow-1 mb-2 pb-4 container-box align-items-center">
          <Col className="pr-5 col-12 col-xl">
            <p className="text-left">
              {t("Player") + " " + parseInt(this.props.id + 1)}
            </p>

            <input
              name="player"
              className="text-input"
              type="text"
              value={this.state.name}
              onChange={(e) => this.handleName(e)}
              onKeyUp={(e) => this.handleName(e)}
              maxLength={40}
            />
          </Col>
          <Col className="flex-grow-0 flex-basis-init w-auto">
            <button
              className={butn[0] + " small-button w-100"}
              onClick={(e) => this.handleAge(e, 0)}
            >
              {t("16 yo and +")}
            </button>
          </Col>
          <Col className="flex-grow-0 flex-basis-init w-auto">
            <button
              className={butn[1] + " small-button w-100"}
              onClick={(e) => this.handleAge(e, 1)}
            >
              {t("4 to 15 yo")}
            </button>
          </Col>
          <Col className="flex-grow-0 flex-basis-init w-auto">
            <button
              className={butn[2] + " small-button w-100"}
              onClick={(e) => this.handleAge(e, 2)}
            >
              {t("3 yo and -")}
            </button>
          </Col>
          <Col className="flex-grow-0 flex-basis-init w-auto">
            {!this.props.automated ? (
              <img
                className="cursor-pointer"
                width="21"
                alt="Delete"
                src={trash}
                onClick={(e) => {
                  let playersNamesAges = JSON.parse(
                    JSON.stringify(this.props.playersNamesAges)
                  );
                  playersNamesAges.splice(this.props.id, 1);
                  this.props.setPlayersNamesAges(playersNamesAges);
                }}
              />
            ) : (
              <></>
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Player));
