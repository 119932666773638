import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import closeImg from "../assets/img/X.svg";
import logoImg from "../assets/img/Logo.png";
import { withTranslation } from "react-i18next";

class Header extends Component {
  handleClose() {
    this.props.handleClose();
    this.props.resetStates();
  }
  render() {
    const { t } = this.props;

    return (
      <React.Fragment>
        <div className="top-bar flex-grow-0 d-block d-md-flex justify-content-center w-100"></div>
        <div className="bottom-bar flex-grow-0 d-block d-md-flex justify-content-center w-100"></div>
        <Row
          className={
            "justify-content-center stage-header " +
            this.props.className
          }
        >
          <Col className="p-5 logoWrapper"> 
            <h4>
              {this.props.back ? (
                <i
                  className="arrow arrow-left mr-4 mb-1 ml-4 cursor-pointer"
                  onClick={this.props.back}
                />
              ) : (
                ""
              )}
              <img src={logoImg} className="logo" />
            </h4>
          </Col>
          <Col
            className={
              "d-none d-md-none flex-grow-0 flex-basis-init stage-header-title text-center " +
              (this.props.stage === 0 ? "" : "d-md-block")
            }
          >
            <p className="active-text text-uppercase smaller m-0">
              {this.props.title ? this.props.title : t("Registration")}
            </p>
            <h3 className="text-uppercase text-overflow-ellipsis">
              {t(this.props.stageTitle)}
            </h3>
          </Col>
          <Col
            className={
              "text-right " + (this.props.stage === 0 ? "d-none" : "")
            }
          >
            {this.props.back ? (
              ""
            ) : (
              <img
                className="closeBtn cursor-pointer "
                src={closeImg}
                alt=""
                onClick={(e) => this.handleClose()}
              />
            )}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default withTranslation()(Header);
