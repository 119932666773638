import Header from "../utilitiesComponents/headerComponent";
import React from "react";
import { connect } from "react-redux";
import { resetStates } from "../actions/team";

class HeaderContainer extends React.Component {
  render() {
    return <Header {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, fishStage } }) => ({
    stage,
    fishStage
  }),
  { resetStates }
)(HeaderContainer);
