import React, { Component } from "react";
import { applyMiddleware, combineReducers, createStore } from "redux";

import App from "./App";
import { Provider } from "react-redux";
import globalStore from "src/store";
import settingReducer from "./actions/setting";
import teamListReducer from "./actions/teamList";
import teamReducer from "./actions/team";
import thunk from "redux-thunk";

class Root extends Component {
  constructor(props) {
    super(props);
    const globalReducers = globalStore.reducers;
    const reducers = {
      ...globalReducers,
      teamReducer,
      teamListReducer,
      settingReducer
    };

    this.store = createStore(combineReducers(reducers), applyMiddleware(thunk));
  }

  render() {
    return (
      <Provider store={this.store} globalStore={globalStore}>
        <App />
      </Provider>
    );
  }
}

export default Root;
