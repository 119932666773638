//@flow

import * as service from "src/api";

import { createAction, createReducer } from "redux-act";

// Create an action creator
const login = createAction();

const defaultState = {
  username: null,
  token: null
};

export const loggedIn = payload => async dispatch => {
  try {
    const data = await service.validateToken();
    if (data.data.success) {
      data.token = data.data.token;
      data.user = data.data.user;
      localStorage.setItem("authToken", data.data.token);
      dispatch(login(data));
      refreshToken();
    } else {
      data.token = "invalid";
      data.user = {};
      dispatch(login(data));
    }
  } catch (e) {
    console.log(e);
  }
};

export const doLogin = payload => async dispatch => {
  try {
    const data = await service.login(payload);
    localStorage.setItem("authToken", data.data.token);
    localStorage.setItem("refreshToken", data.data.refreshToken);
    dispatch(login(data.data));
    refreshToken();
  } catch (e) {
    const invalidValue = {
      user: { username: null },
      token: "invalid"
    };
    dispatch(login(invalidValue));
    console.log(e);
  }
};

var refreshInterval = null;
const refreshToken = () => {
  refreshInterval = setInterval(async () => {
    const data = await service.refreshToken();
    localStorage.setItem("authToken", data.data.token);
  }, 600000);
};

export const doUnlogin = payload => async dispatch => {
  try {
    await service.unlogin(payload);
    var invalidValue = {
      user: { username: null },
      token: "invalid"
    };
    dispatch(login(invalidValue));
    clearInterval(refreshInterval);
  } catch (e) {
    invalidValue = {
      user: { username: null },
      token: "invalid"
    };
    dispatch(login(invalidValue));
    console.log(e);
  }
};

const UserReducer = createReducer(
  {
    [login]: (state, data) => {
      return {
        ...state,
        username: data.user.username,
        token: data.token
      };
    }
  },
  defaultState
);

export default UserReducer;
