import { incrementStage, setFishStage, setSelectedFish } from "../actions/team";

import React from "react";
import Species from "./speciesComponent";
import { connect } from "react-redux";

class SpeciesContainer extends React.Component {
  render() {
    return <Species {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, selectedFish, fishStage } }) => ({
    stage,
    selectedFish,
    fishStage
  }),
  {
    setSelectedFish,
    incrementStage,
    setFishStage
  }
)(SpeciesContainer);
