import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import closeImg from "../assets/img/X.svg";
import logoImg from "../assets/img/Logo.png";
import { withRouter } from "react-router-dom";

class Header extends Component {
  handleClose() {
    this.props.doUnlogin();
  }
  render() {
    return (
      <React.Fragment>
        <Row className="flex-column flex-nowrap m-0 w-100">
          <Col
            className={
              "top-bar flex-grow-0 d-block d-md-flex justify-content-center "
            }
          ></Col>
          <Col
            className={
              "bottom-bar flex-grow-0 d-block d-md-flex justify-content-center "
            }
          ></Col>
        </Row>
        <Row
          className={
            "justify-content-center flex-basis-100 mb-4 stage-header " +
            this.props.className
          }
        >
          <Col className="p-5">
            <h5><img src={logoImg} className="logo" /></h5>
          </Col>
          <Col className="flex-grow-0 flex-basis-init stage-header-title admin-side text-center ">
            <Row>
              <Col className="col-5 offset-1">
                <p
                  className={
                    "text-uppercase " +
                    (this.props.activePage === "teamsList" ? "active-text" : "")
                  }
                  onClick={e => {
                    this.props.history.push("/user");
                  }}
                >
                  ÉQUIPES
                </p>
              </Col>
              <Col className="col-4">
                <p
                  className={
                    "text-uppercase " +
                    (this.props.activePage === "settings" ? "active-text" : "")
                  }
                  onClick={e => {
                    this.props.history.push("/setting");
                  }}
                >
                  PANNEAU DE CONTRÔLE
                </p>
              </Col>
            </Row>
          </Col>
          <Col className="text-right p-5 closeBtn">
            <img
              className="cursor-pointer"
              src={closeImg}
              alt=""
              onClick={(e) => this.handleClose()}
            />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}
export default withRouter(Header);
