import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import FishViewerComponent from "./fishModelViewerComponent";
import ProgressBar from "./progressBarComponent";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import closeImg from "../assets/img/X.svg";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class fishInfosComponent extends Component {
  handleClose() {
    this.props.setFishStage(null);
  }
  handleNext() {
    var next = this.props.fishStage === 3 ? 0 : this.props.fishStage + 1;
    this.props.setFishStage(next);
  }
  handleBack() {
    var back = this.props.fishStage === 0 ? 3 : this.props.fishStage - 1;

    this.props.setFishStage(back);
  }
  handleUnselect() {
    if (this.props.selectedFish === this.props.id) {
      this.props.setSelectedFish(null);
    }
    this.handleClose();
  }

  handleSelect() {
    this.props.setSelectedFish(this.props.id);
    this.props.setFormValidated(true);
    const nextStage = this.props.stage + 1;
    this.props.history.push(`/${nextStage}`);
    this.props.incrementStage(this.props.stage);
    this.handleClose();
  }

  render() {
    const { t } = this.props;
    return (
      <div className="modal-container w-100 justify-content-center align-items-center flex-fill">
        <ScrollContainer
          stopScrollPropagation={true}
          smoothScrolling={true}
          className="col-lg-10 offset-lg-1 item-box item-modal col max-vh-100"
          contentClassName="item-box-content"
          speed={0.8}
          horizontal={false}
          parentClassName="align-content-center vh-100 row"
        >
          <button
            className="idle-button small-button modal-close-button square-button"
            onClick={(e) => this.handleClose()}
          >
            <img src={closeImg} className="cursor-pointer" alt="" />
          </button>
          <button
            className="modal-button small-button modal-next-button square-button"
            onClick={(e) => this.handleNext()}
          >
            <i className="arrow arrow-right"></i>
          </button>
          <button
            className="modal-button small-button modal-back-button square-button"
            onClick={(e) => this.handleBack()}
          >
            <i className="arrow arrow-left"></i>
          </button>
          <Row>
            <Col>
              <Row className="pt-5">
                <Col className="text-left d-flex flex-wrap col-sm-12 col-md-6 fish-info">
                  <Row className="flex-basis-100">
                    <Col>
                      <h3 className="active-text">
                        {t(this.props.fishesCharacteristic[this.props.id])}
                      </h3>
                      <h1>{t(this.props.fishesNameCaps[this.props.id])}</h1>
                      <p className="smaller">
                        {t(this.props.fishesText[this.props.id])}
                      </p>
                    </Col>
                  </Row>
                  <Row className="mt-4 mb-4">
                    <Col>
                      <p className="smaller active-text text-uppercase">
                        {t("Status")}
                      </p>
                      <h3 className="text-uppercase">
                        {t(this.props.fishesStatus[this.props.id])}
                      </h3>
                    </Col>
                  </Row>
                  <Row className="align-self-end w-100 pb-5">
                    <Col className="text-left col-md-11">
                      <Row>
                        <Col xs={5} className="col-md-5 col-lg-4 text-lg-right">
                          <p className="smaller">{t("Record")}</p>
                        </Col>
                        <Col sm={12} className="d-md-none d-lg-block col-lg-4">
                          <ProgressBar
                            className="green-bar"
                            now={this.props.fishesRecord[this.props.id]}
                            max={104.1}
                          />
                        </Col>
                        <Col>
                          <p>{this.props.fishesRecord[this.props.id]} cm</p>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={5} className="col-md-5 col-lg-4 text-lg-right">
                          <p className="smaller">{t("Longevity")}</p>
                        </Col>
                        <Col sm={12} className="d-md-none d-lg-block col-lg-4">
                          <ProgressBar
                            className="green-bar"
                            now={this.props.fishesLongevity[this.props.id]}
                            max={30}
                          />
                        </Col>
                        <Col>
                          <p>
                            {this.props.fishesLongevity[this.props.id]}{" "}
                            {t("years old")}
                          </p>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col xs={5} className="col-md-5 col-lg-4 text-lg-right">
                          <p className="smaller">{t("Combativity")}</p>
                        </Col>
                        <Col sm={12} className="d-md-none d-lg-block col-lg-4">
                          <ProgressBar
                            className="green-bar"
                            now={this.props.fishesCombativity[this.props.id]}
                            max={3}
                          />
                        </Col>
                        <Col>
                          <p>
                            {t(
                              this.props.fishesCombativityText[
                                this.props.fishesCombativity[this.props.id] - 1
                              ]
                            )}
                          </p>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
                <Col className="d-flex align-items-center justify-content-center flex-column col-sm-12 col-md-6">
                  <Row className="flex-basis-100 w-100">
                    <Col className="d-flex flex-column justify-content-between">
                      <Row></Row>
                      <Row className="flex-grow-1">
                        <Col>
                          <FishViewerComponent
                            src={this.props.fishesImage[this.props.id]}
                          />
                        </Col>
                      </Row>
                      <Row className="pt-5 mt-5 justify-content-center">
                        <Col className="flex-grow-0 flex-basis-init w-auto mb-5">
                          <button
                            className="active-btn-gradient small-button"
                            onClick={() => this.handleSelect()}
                          >
                            {t("Choose this specie")} <i className="check"></i>
                          </button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
        </ScrollContainer>
      </div>
    );
  }
}

export default withRouter(withTranslation()(fishInfosComponent));
