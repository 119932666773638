import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import Footer from "./footerContainer";
import Header from "./headerContainer";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

class RegistrationComponent extends Component {
  constructor(props) {
    super(props);
    this.inputTeamNameRef = React.createRef();
    this.inputEmailRef = React.createRef();
    this.checkboxNoEmailRef = React.createRef();
    this.state = {
      teamName: this.props.teamName,
      email:
        this.props.email === "no-reply@acoatica.com"
          ? ""
          : this.props.email,
      displayTeamNameInfo: false,
      displayEmailInfo: false,
      defaultEmail: "no-reply@acoatica.com",
      isChecked:
        this.props.email === "no-reply@acoatica.com" ? true : false,
    };
  }

  setEmail(e) {
    var email = e.target.value;
    if (email !== "") {
      this.setState({ ...this.state, isChecked: false }, () =>
        this.setEmailAndValidate(email)
      );
    } else {
      this.setEmailAndValidate(email);
    }
  }

  setEmailAndValidate(email) {
    
    this.props.setEmail(email);
    this.setState(
      {
        ...this.state,
        email: email === this.state.defaultEmail ? "" : email,
      },
      () => {
        this.checkFormValidated();
      }
    );
  }

  toggleChange(e) {
    var email = e.target.checked ? this.state.defaultEmail : this.state.email;
    this.setState(
      {
        isChecked: e.target.checked,
      },
      () => {
        this.setEmailAndValidate(email);
      }
    );
  }

  setTeamName(e) {
    this.props.setTeamName(e.target.value);
    this.setState(
      {
        ...this.state,
        teamName: e.target.value,
      },
      () => {
        this.checkFormValidated();
      }
    );
  }
  checkFormValidated() {
    if (
      (this.inputEmailRef.current.validity.valid ||
        this.props.email === this.state.defaultEmail) &&
      this.inputTeamNameRef.current.validity.valid
    ) {
      this.props.setFormValidated(true);
      this.inputEmailRef.current.classList.remove("is-invalid");
      this.inputTeamNameRef.current.classList.remove("is-invalid");

      return true;
    }
    this.props.setFormValidated(false);
    return false;
  }
  validation(e) {
    e.preventDefault();
    if (
      !this.inputEmailRef.current.validity.valid &&
      this.props.email !== this.state.defaultEmail
    ) {
      this.inputEmailRef.current.classList.add("is-invalid");
    } else {
      this.inputEmailRef.current.classList.remove("is-invalid");
    }

    if (!this.inputTeamNameRef.current.validity.valid) {
      this.inputTeamNameRef.current.classList.add("is-invalid");
    } else {
      this.inputTeamNameRef.current.classList.remove("is-invalid");
    }
    if (this.checkFormValidated()) {
      const nextStage = this.props.stage + 1;
      this.props.history.push(`/${nextStage}`);
      this.props.incrementStage(this.props.stage);
    }
  }
  componentDidMount() {
    this.checkFormValidated();
  }
  componentWillUnmount() {
    this.props.setFormValidated(true);
  }
  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Header stageTitle={t("YOUR TEAM")} handleClose={()=>{clearTimeout(this.t);}} />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="registrationCpmt w-100 justify-content-center align-items-center flex-fill">
          <Col className="col-xl-6 here">
            <Row className="d-flex flex-column text-center">
              <Col>
                <h4>
                  {t(
                    "The name of your team and the species of fish chosen will accompany you throughout the experience and will appear in different places on the site, visible to all. It is therefore important to choose them carefully. Please enter the name of your team"
                  )}
                </h4>
              </Col>
            </Row>
            <Row className="mt-5">
              <Col className="col-12 col-md-6">
                <Row className="mt-4">
                  <Col>
                    <p className="m-0">{t("Name of your team")}</p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <input
                      tabIndex="1"
                      className="text-input form-control"
                      type="text"
                      name="teamName"
                      onChange={(e) => this.setTeamName(e)}
                      onKeyUp={(e) => this.setTeamName(e)}
                      maxLength={40}
                      required="required"
                      value={this.state.teamName}
                      ref={this.inputTeamNameRef}
                    />
                  </Col>
                </Row>
                
              </Col>
              <Col>
                <Row className="mt-4">
                  <Col>
                    <p className="m-0">{t("Email")}</p>
                  </Col>
                </Row>
                <Row className="mt-2">
                  <Col>
                    <input
                      tabIndex="2"
                      name="email"
                      className="text-input form-control"
                      type="email"
                      id="email"
                      onChange={(e) => this.setEmail(e)}
                      onKeyUp={(e) => this.setEmail(e)}
                      maxLength={100}
                      required
                      value={
                        this.state.email !== this.state.defaultEmail
                          ? this.state.email
                          : ""
                      }
                      ref={this.inputEmailRef}
                    />
                  </Col>
                </Row>
                <Row className="mt-4 mt-sm-2">
                  <Col>
                    <label>
                      <div className="custom-control custom-checkbox">
                        <input
                          tabIndex="3"
                          type="checkbox"
                          className="custom-control-input"
                          id="customCheck"
                          checked={this.state.isChecked}
                          onChange={(e) => this.toggleChange(e)}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheck"
                        >
                          <h5>{t("I do not have an email")}</h5>
                        </label>
                      </div>
                    </label>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col className="align-self-center">
                <p className="smaller notice">
                  {t(
                    "This email address will not be used for advertising purposes. You will receive a confirmation email indicating the validity of your registration when it is completed. Following your visit, your ranking as well as a team picture will be emailed to you free of charge."
                  )}
                </p>
              </Col>
            </Row>
          </Col>
        </Row>
        </ScrollContainer>
        <Footer continueCallback={(e) => this.validation(e)} />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(RegistrationComponent));
