import * as api from "src/api";

export const fetchTeamList = (
  pageSize,
  page,
  search,
  query,
  sort,
  desc,
  date
) => {
  var link = api.urls.SERVER_API;
  link = page ? link + "/api/teams?p=" + page : link + "/api/teams?p=1";
  link = pageSize ? link + "&size=" + pageSize : link;
  link = search ? link + "&s=" + search : link;
  link = query ? link + "&q=" + query : link;
  link = sort ? link + "&sort=" + sort : link;
  link = date ? link + "&date=" + date : link;
  link = typeof desc === "boolean" ? link + "&desc=" + desc : link;

  return api.instance.get(link, {
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};

export const fetchTeam = (teamId) => {
  var link = api.urls.SERVER_API + "/api/teams/" + teamId;
  return api.instance.get(link, {
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};

export const fetchPlayer = (rfid) => {
  var link = api.urls.SERVER_API + "/api/players/rfid/" + rfid;
  return api.instance.get(link, {
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};

export const changeName = (playerId, playerName) => {
  return api.instance.put(
    api.urls.SERVER_API + "/api/players/" + playerId,
    {
      name: playerName,
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
    }
  );
};

export const saveInformation = (team) => {
  return api.instance.post(
    api.urls.SERVER_API + "/api/teams",
    {
      team,
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
    }
  );
};

export const updateInformation = (team, id) => {
  return api.instance.put(
    api.urls.SERVER_API + "/api/teams/" + id,
    {
      team,
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
    }
  );
};

export const deleteTeam = (teamId) => {
  return api.instance.delete(api.urls.SERVER_API + "/api/teams/" + teamId, {
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};

export const addPlayer = (teamId, name, age) => {
  return api.instance.post(
    api.urls.SERVER_API + "/api/players",
    {
      name: name,
      age: age,
      teamId: teamId,
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
    }
  );
};

export const deletePlayer = (playerId) => {
  return api.instance.delete(api.urls.SERVER_API + "/api/players/" + playerId, {
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};

export const markAsCompleted = (teamId) => {
  return api.instance.put(
    api.urls.SERVER_API + "/api/teams/" + teamId,
    {
      team: {
        status: "Completed",
      },
    },
    {
      headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
    }
  );
};

export const fetchSallInteractivePlayersInfo = (playerIds) => {
  return api.instance.get(api.urls.SERVER_API + "/api/players/", {
    params: { playerIds: playerIds },
    headers: { Authorization: `bearer ${localStorage.getItem("authToken")}` },
  });
};
