import {
  addPlayer,
  changeName,
  deletePlayer,
  fetchTeam,
  setPlayers,
  updateInformation,
} from "../actions/team";

import React from "react";
import Team from "./teamComponent";
import { connect } from "react-redux";

class TeamContainer extends React.Component {
  render() {
    return <Team {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      players,
      teamName,
      rfid,
      adultsCount,
      kidsCount,
      toddlersCount,
      language,
      email,
      selectedFish,
      isTeamLoading
    },
  }) => ({
    players,
    teamName,
    rfid,
    adultsCount,
    kidsCount,
    toddlersCount,
    language,
    email,
    selectedFish,
    isTeamLoading
  }),
  {
    fetchTeam,
    changeName,
    setPlayers,
    deletePlayer,
    addPlayer,
    updateInformation,
  }
)(TeamContainer);
