import {
  incrementStage,
  setEmail,
  setFormValidated,
  setTeamName
} from "../actions/team";

import React from "react";
import Registration from "./registrationComponent";
import { connect } from "react-redux";

class RegistrationContainer extends React.Component {
  render() {
    return <Registration {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, teamName, email, formValidated } }) => ({
    stage,
    teamName,
    email,
    formValidated
  }),
  {
    setTeamName,
    setEmail,
    incrementStage,
    setFormValidated
  }
)(RegistrationContainer);
