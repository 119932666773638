import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Footer from "./footerContainer";
import Header from "./headerContainer";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

import FishViewerComponent from "./fishModelViewerFinalComponent";

class Modalities extends Component {
  state = {};

  validation(e) {
    e.preventDefault();
    const nextStage = this.props.stage + 1;
    this.props.history.push(`/${nextStage}`);
    this.props.incrementStage(this.props.stage);
  }

  render() {
    const { t } = this.props;

    const teamName = this.props.teamName !== "" ? this.props.teamName : "--";
    const email = this.props.email !== "" ? this.props.email : "--";

    const playerCount = (
      <Row>
        <Col className="flex-basis-init w-auto">
          <h4 className="forceNoPadding">
            {this.props.adultsCount} {t("Adult(s)")}
          </h4>
        </Col>
        <Col className="flex-basis-init w-auto">
          <h4 className="forceNoPadding">
            {this.props.kidsCount} {t("Kid(s)")}
          </h4>
        </Col>
        <Col className="flex-basis-init w-auto">
          <h4 className="forceNoPadding">
            {this.props.toddlersCount} {t("Toddler(s)")}
          </h4>
        </Col>
      </Row>
    );

    var fishName =
      this.props.selectedFish != null
        ? this.props.fishesName[this.props.selectedFish]
        : "---";
    var fishInfo;
    var teamInfo2;
    var fishNamePart;
    if (this.props.stage === 5) {
      fishNamePart = (
        <React.Fragment>
          <Row className="mt-4">
            <Col className="col-12">
              <p className="forceNoPadding">{t("Species of fish")}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <h4 className="forceNoPadding">{t(fishName)}</h4>
            </Col>
          </Row>
        </React.Fragment>
      );
      teamInfo2 = (
        <React.Fragment>
          <Row>
            <Col className="col-12">
              <p className="forceNoPadding">{t("Email")}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">
              <h4 className="forceNoPadding">{email}</h4>
            </Col>
          </Row>
          <Row className="mt-4">
            <Col className="col-12">
              <p className="forceNoPadding">{t("Players")}</p>
            </Col>
          </Row>
          <Row>
            <Col className="col-12">{playerCount}</Col>
          </Row>
        </React.Fragment>
      );
      var fishId;
      switch (fishName) {
        case "Brook trout":
          fishId = 3;
          break;
        case "Walleye":
          fishId = 2;
          break;
        case "Striped bass":
          fishId = 1;
          break;
        case "Copper redhorse":
          fishId = 0;
          break;
        default:
          fishId = 0;
      }
      fishInfo = (
        <Row>
          <Col className="h-200">
            <FishViewerComponent
                    src={this.props.fishesImage[fishId]}
                  />
          </Col>
        </Row>
      );
    }
    var teamInfo;
    if (this.props.stage > 2 && this.props.stage < 6) {
      teamInfo = (
        <React.Fragment>
          <Row>
            <Col className="text-left col-12">
              <p className="forceNoPadding">{t("Fish Bank")}</p>
            </Col>
          </Row>
          <Row>
            <Col className="content-header text-left col-12">
              <h4 className="forceNoPadding">{teamName}</h4>
            </Col>
          </Row>
          {fishNamePart}
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        <Header stageTitle={t("SUMMARY")} handleClose={()=>{clearTimeout(this.t);}} />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="modalitiesCpmt w-100 justify-content-center align-items-center flex-fill">
          <Col className="col-sm-12 d-flex flex-column">
            <Row className="header pt-5 pb-4 justify-content-center">
              <Col className="col-md-12 col-lg-9">
                <Row>
                  <Col className="text-center">{fishInfo}</Col>
                </Row>
                <Row className="justify-content-between pt-5">
                  <Col className="flex-grow-0 flex-basis-init w-auto">
                    {teamInfo}
                  </Col>
                  <Col className="flex-grow-0 flex-basis-init w-auto">
                    {teamInfo2}
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        </ScrollContainer>
        <Footer continueCallback={(e) => this.validation(e)} />
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(Modalities));
