import "react-table/react-table.css";

import { Col, Dropdown, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "../utilitiesComponents/headerComponent";
import Modal from "../utilitiesComponents/modalComponent";
import bgVid from "../assets/vid/bg.mp4";
import { withRouter } from "react-router-dom";

class AddTeam extends Component {
  state = {
    displayAddTeamConfirmation: false
  };

  constructor(props) {
    super(props);
    this.inputEmailRef = React.createRef();
    this.inputNameRef = React.createRef();
    this.emailIsValide = false;
    props.resetStates();
    this.state = { isChecked : false };
  }

  
  setAdultsCount(e) {
    this.props.setAdultsCount(e);
  }

  setKidsCount(e) {
    this.props.setKidsCount(e);
  }

  setToddlersCount(e) {
    this.props.setToddlersCount(e);
  }

  setTeamName(e) {
    this.inputNameRef.current.classList.remove("is-invalid");
    this.props.setTeamName(e.target.value);
  }

  setEmail(e) {
    //console.log(this.props.email);
    if (/^[a-zA-Z0-9_.]+@(?:[a-zA-Z0-9]+\.)+[A-Za-z]+$/.test(this.props.email)) { 
      this.emailIsValide = true;
      this.inputEmailRef.current.classList.remove("is-invalid");
    } else {
      this.emailIsValide = false;
      // if (this.props.email.length > 3){
      //   this.inputEmailRef.current.classList.add("is-invalid");
      // }
      
    }

    
    var email = e.target.value;

    if (email !== "") {
      this.setState({ ...this.state, isChecked: false }, () =>
        this.setEmailAndValidate(email)
      );
    } else {
      this.setEmailAndValidate(email);
    }
  }

  // email confirmation
  setEmailAndValidate(email) {
    
    this.props.setEmail(email);
    this.setState(
      {
        ...this.state,
        email: email === this.state.defaultEmail ? "" : email,
      },
      () => {
        this.checkFormValidated();
      }
    );
  }


  toggleChange(e) {
    var email = e.target.checked ? this.state.defaultEmail : this.state.email;
    this.setState(
      {
        isChecked: e.target.checked,
      },
      () => {
        this.setEmailAndValidate(email);
      }
    );
  }

  checkFormValidated() {
    
    var inputRef = this.inputEmailRef;
    var inputEmailRefCurrent = this.inputEmailRef.current.validity.valid;
    var email = this.props.email;
    var defaultEmail = this.state.defaultEmail;
        
    if (
      ( inputEmailRefCurrent ||
        email === this.state.defaultEmail)
    ) {
      
      this.props.setFormValidated(true);
      return true;
    }
    
    this.props.setFormValidated(false);
    return false;
  }
  // end of email confirmation
  

  setSelectedFish(e) {
    this.props.setSelectedFish(e);
  }

  setLanguage(e) {
    this.props.setLanguage(e);
  }

  async AddTeam() {
    let count =
      parseInt(this.props.toddlersCount) +
      parseInt(this.props.kidsCount) +
      parseInt(this.props.adultsCount);

    let players = [];
    for (let index = 0; index < count; index++) {
      let num = index + 1;
      players.push({
        name: this.getLanguage() === 0 ? `Joueur ${num}` : `Player ${num}`
      });
    }
    var success = await this.props.postSaveInformation({
      email: this.props.email,
      name: this.props.teamName,
      kids_count: this.props.kidsCount,
      adults_count: this.props.adultsCount,
      toddlers_count: this.props.toddlersCount,
      fish_type: this.props.selectedFish,
      language: this.props.language,
      players: players
    });
    
    this.resetTeam();

    /*if(success)
      this.resetTeam();
    else
      this.setState({ ...this.state, ErrorMessage : "Erreur survenue lors de l'ajout d'une équipe" });*/

    this.setState({ ...this.state, displayAddTeamConfirmation: false });
  }

  
  handleAddTeam(e) {
    // this.setEmail(e);
    var nameRef = false;
    var emailRef = false;
   
    // Highlighting the name input if empty
    if(this.props.teamName === '') {
      this.inputNameRef.current.classList.add("is-invalid");
      this.nameRef = false;
    } else {
      this.inputNameRef.current.classList.remove("is-invalid");
      this.nameRef = true;
    }
    
    // Onclick, remove the invalid's class if checkbox is checkded
    if(this.state.isChecked){
      this.inputEmailRef.current.classList.remove("is-invalid");
    }
    

    if((this.props.teamName !== '') && this.state.isChecked){  
      this.emailRef = true;
      this.addTeamConfirmation(this.nameRef, this.emailRef);
    } else {
      this.emailRef = false;
      if (!this.state.isChecked &&
        !this.inputEmailRef.current.validity.valid
      ) {
        this.inputEmailRef.current.classList.add("is-invalid");
      } else {
        if(!this.state.isChecked){
          this.inputEmailRef.current.classList.remove("is-invalid");
        }
        this.emailRef = true;
        if(this.emailIsValide){
          this.addTeamConfirmation(this.nameRef, this.emailRef);
        } else {
          this.inputEmailRef.current.classList.add("is-invalid");
        }
      }
    }
  }

  // Validate if name and email are filled 
  addTeamConfirmation(name, email){
    if(name && email){
      this.setState({ ...this.state, displayAddTeamConfirmation: true });
    }
  }

  resetTeam() {
    this.props.resetStates();
    this.getBack();
  }

  getBack() {
    const to = "/user";
    this.props.history.push(to);
  }

  getLanguage() {
    let index = this.props.languageOptions.findIndex(
      language => language.value === this.props.language
    );
    return index > -1 ? index : 0;
  }

  componentWillUnmount() {
    this.props.setFormValidated(true);
  }

  render() {
    let selectedFish;
    if (
      this.props.selectedFish === null ||
      this.props.selectedFish === undefined ||
      this.props.selectedFish > 3
    ) {
      selectedFish = 0;
      this.props.setSelectedFish(selectedFish);
    } else {
      selectedFish = this.props.selectedFish;
    }
    const selectedLanguage = this.getLanguage();

    var adultsCount =
      this.props.adultsCount === null ? "0" : this.props.adultsCount;

    const kidsCount =
      this.props.kidsCount === null ? "0" : this.props.kidsCount;

    const toddlersCount =
      this.props.toddlersCount === null ? "0" : this.props.toddlersCount;

    var dropdownAdultsCountOptions = [];
    var dropdownKidsCountOptions = [];
    var dropdownToddlersCountOptions = [];

    for (let i = 0; i <= 15; i++) {
      dropdownAdultsCountOptions.push(
        <Dropdown.Item
          className={i === parseInt(adultsCount) ? "active" : ""}
          eventKey={i}
          key={i}
        >
          {i}
        </Dropdown.Item>
      );

      dropdownKidsCountOptions.push(
        <Dropdown.Item
          className={i === parseInt(kidsCount) ? "active" : ""}
          eventKey={i}
          key={i}
        >
          {i}
        </Dropdown.Item>
      );

      dropdownToddlersCountOptions.push(
        <Dropdown.Item
          className={i === parseInt(toddlersCount) ? "active" : ""}
          eventKey={i}
          key={i}
        >
          {i}
        </Dropdown.Item>
      );
    }

    var dropdownFishOptions = [];
    this.props.fishOptions.forEach(fish => {
      var cls = "";
      if (fish.value === selectedFish) {
        cls = "active";
      }
      dropdownFishOptions.push(
        <Dropdown.Item className={cls} eventKey={fish.value} key={fish.value}>
          {fish.label}
        </Dropdown.Item>
      );
    });

    var dropdownLanguageOptions = [];
    this.props.languageOptions.forEach(language => {
      var cls = "";
      if (language.value === this.props.language) {
        cls = "active";
      }
      dropdownLanguageOptions.push(
        <Dropdown.Item
          className={cls}
          eventKey={language.value}
          key={language.value}
        >
          {language.label}
        </Dropdown.Item>
      );
    });
    return (
      <React.Fragment>
        <Header
          stageTitle="AJOUT ÉQUIPE"
          resetStates={() => ""}
          title="ADMINISTRATION"
          back={() => this.resetTeam()}
        />
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Modal
          title="ATTENTION"
          text="Êtes vous sur de vouloir ajouter cette équipe ?"
          type="yesno"
          icon="warning"
          display={this.state.displayAddTeamConfirmation}
          onClose={e =>
            this.setState({
              ...this.state,
              displayAddTeamConfirmation: false
            })
          }
          onYes={e => this.AddTeam()}
          onNo={e =>
            this.setState({
              ...this.state,
              displayAddTeamConfirmation: false
            })
          }
        />
        <Row className="flex-fill pt-5 mt-5">
          <Col className="d-flex flex-column justify-content-between">
            {this.state.ErrorMessage &&
          <div style={{color:"red", marginLeft:"auto", marginRight: "auto",marginTop:"30px", fontSize:"25px"}}>{this.state.ErrorMessage}</div>
            }
            <Row className="mt-5">
              <Col className="d-flex flex-column justify-content-between col-8 offset-2">
                <Row>
                  <Col>
                    <Row>
                      <Col>
                        <p>Nom d'équipe</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <input
                          className="namelInput text-input"
                          value={this.props.teamName}
                          onChange={e => this.setTeamName(e)}
                          ref={this.inputNameRef}
                        ></input>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <p>Courriel</p>
                      </Col>
                    </Row>
                    
                    <Row>
                      <Col>
                        <input
                          className="emailInput text-input"
                          value={this.props.email}
                          
                          onChange={(e) => this.setEmail(e, this.inputEmailRef)}
                          onKeyUp={(e) => this.setEmail(e)}
                          maxLength={100}
                          required
                          value={
                            this.state.email !== this.state.defaultEmail
                              ? this.state.email
                              : ""
                          }
                          ref={this.inputEmailRef}

                        ></input>
                      </Col>
                    </Row>
                    <Row className="mt-4 mt-sm-2">
                      <Col>
                        <label>
                          <div className="custom-control custom-checkbox">
                            <input
                              tabIndex="3"
                              type="checkbox"
                              className="custom-control-input"
                              id="customCheck"
                              checked={this.state.isChecked}
                              onChange={(e) => this.toggleChange(e)}
                            />
                            <label
                              className="custom-control-label"
                              htmlFor="customCheck"
                            >
                              <h5>Je n'ai pas de courriel</h5>
                            </label>
                          </div>
                        </label>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <p>Espèce de poisson</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown onSelect={e => this.setSelectedFish(e)}>
                          <Dropdown.Toggle variant className="dropdown-control">
                            {this.props.fishOptions[selectedFish].label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownFishOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <p>Langue préférée</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown onSelect={e => this.setLanguage(e)}>
                          <Dropdown.Toggle variant className="dropdown-control">
                            {this.props.languageOptions[selectedLanguage].label}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownLanguageOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                  <Col className="ml-5">
                    <Row>
                      <Col>
                        <p>Adulte(s)</p>
                      </Col>
                      <Col className="flex-basis-init w-auto flex-grow-0">
                        <p>16 ans et +</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown onSelect={e => this.setAdultsCount(e)}>
                          <Dropdown.Toggle variant className="dropdown-control">
                            {adultsCount}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownAdultsCountOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <p>Enfant(s)</p>
                      </Col>
                      <Col className="flex-basis-init w-auto flex-grow-0">
                        <p>6 à 15 ans</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown onSelect={e => this.setKidsCount(e)}>
                          <Dropdown.Toggle variant className="dropdown-control">
                            {kidsCount}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownKidsCountOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                    <Row className="mt-4">
                      <Col>
                        <p>Bambin(s)</p>
                      </Col>
                      <Col className="flex-basis-init w-auto flex-grow-0">
                        <p>5 ans et -</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Dropdown onSelect={e => this.setToddlersCount(e)}>
                          <Dropdown.Toggle variant className="dropdown-control">
                            {toddlersCount}
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="dropdown-menu">
                            {dropdownToddlersCountOptions}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row className="d-flex pb-5 footer justify-content-center">
              <Col className="mt-4 flex-grow-0">
                <button
                  className="primary-button"
                  onClick={e => this.handleAddTeam(e)}
                >
                  Ajouter
                </button>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(AddTeam);
