import {
  fetchTeam,
  setEmail,
  setLanguage,
  setSelectedFish,
  setTeamName
} from "../actions/team";

import React from "react";
import Sidebar from "./sidebarComponent";
import { connect } from "react-redux";

class SidebarContainer extends React.Component {
  render() {
    return <Sidebar {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      teamName,
      email,
      language,
      selectedFish,
      originalData,
      fishOptions,
      languageOptions,
      isTeamLoading
    }
  }) => ({
    teamName,
    email,
    language,
    selectedFish,
    originalData,
    fishOptions,
    languageOptions,
    isTeamLoading
  }),
  {
    fetchTeam,
    setTeamName,
    setEmail,
    setLanguage,
    setSelectedFish
  }
)(SidebarContainer);
