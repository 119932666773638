import React, { Component } from "react";

import ScrollArea from "react-scrollbar";

class ScrollContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      count: false
    };
  }
  
  handleMouseDown(e) {
    this.isDragging = true;
  }
  handleMouseUp(e) {
    this.isDragging = false;
  }
  handleMouseMove(e) {
    if (!this.isDragging) return;
    let top = this.ScrollArea.state.topPosition;

    this.ScrollArea.scrollArea.scrollYTo(top - e.movementY);
    
  }
  render() {    
    return (
      <div
        onMouseDown={e => this.handleMouseDown(e)}
        onMouseMove={e => this.handleMouseMove(e)}
        onMouseUp={e => this.handleMouseUp(e)}
        className={this.props.parentClassName + ' ' + (this.state.count ? 'active' : '' ) }
      >
        
        <ScrollArea 
        onScroll={(x) => {
          this.state = x;
          if(this.state.topPosition >= 80){
            this.setState({ count: true })
          }
          else {
            this.setState({ count: false })
          }
        }}
        ref={x => (this.ScrollArea = x)} {...this.props}>{this.props.children}
        </ScrollArea>
        
      </div>
    );
  }
}

export default ScrollContainer;
