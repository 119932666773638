import React, { Component } from "react";

import Routes from "src/routes";
import { withRouter } from "react-router";

class AppLayout extends Component {
  render() {
    return (
      <div>
        <main>
          <Routes />
        </main>
      </div>
    );
  }
}

export default withRouter(AppLayout);
