import { decrementStage, resetStates } from "../actions/team";

import Footer from "./footerComponent";
import React from "react";
import { connect } from "react-redux";

class FooterContainer extends React.Component {
  render() {
    return <Footer {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, formValidated } }) => ({
    stage,
    formValidated
  }),
  {
    resetStates,
    decrementStage
  }
)(FooterContainer);
