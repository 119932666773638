import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "./headerContainer";
import pin from "../assets/img/Pin.svg";
import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";
import ScrollContainer from "../utilitiesComponents/scrollContainerComponent";

class GoodVisit extends Component {
  t = "";
  constructor(props) {
    super(props);
    var self = this;
    self.saveAndContinue();
  }

  handleSaveInformation() {
    clearTimeout(this.t);
    this.props.resetStates();
    this.props.history.push(`/${0}`);
    this.props.incrementStage(this.props.stage);
  }

  saveAndContinue() {
    const players = [];
    for (let i = 0; i < this.props.playersNamesAges.length; i++) {
      const name = {
        name: this.props.playersNamesAges[i].name
          ? this.props.playersNamesAges[i].name
          : this.props.t("Player") + " " + parseInt(i + 1),
        age: this.props.playersNamesAges[i].age,
      };
      players.push(name);
    }
    this.props.postSaveInformation({
      email: this.props.email,
      name: this.props.teamName,
      kids_count: this.props.kidsCount,
      adults_count: this.props.adultsCount,
      toddlers_count: this.props.toddlersCount,
      fish_type: this.props.selectedFish,
      language: this.props.language,
      players: players,
    });

    if (this.props.env === "onsite"){
      this.t = setTimeout(this.handleSaveInformation.bind(this), 15000);
    }
  }

  render() {
    const { t } = this.props;
    return (
      <React.Fragment>
        <Header stageTitle={t("COMPLETED")} handleClose={()=>{clearTimeout(this.t);}} />
        <ScrollContainer
          speed={0.8}
          smoothScrolling={true}
          className={"main-container"}
          contentClassName={"container-fluid d-flex "}
          horizontal={false}
          parentClassName="vh-100 w-100"
        >
        <Row className="goodVisitCmpt flex-grow-0 w-100 justify-content-center align-items-center flex-fill">
          <Col className="col-sm-10 col-md-8">
            <Row className="d-flex flex-column text-center">
              <Col
                // onClick={e => this.handleSaveInformation(e)}
                style={{ cursor: "pointer" }}
              >
                <Row>
                  <Col>
                    <h2>{t("Your team's registration is now complete.")}</h2>
                    <h3
                      className={
                        "mt-5 " + (this.props.env === "onsite" ? "" : "d-none")
                      }
                    >
                      {t(
                        "Immerse yourself in the Acoatica experience. Pick up your bracelets at the counter."
                      )}
                    </h3>
                    <h3
                      className={
                        "mt-5 "
                      }
                    >
                      {t(
                        "Check-in at Parc Découverte Nature at the time indicated on the purchase confirmation to begin the Acoatica interactive survival experience."
                      )}
                    </h3>
                  </Col>
                </Row>
                <Row
                  className={
                    "mt-5 " + (this.props.env === "onsite" ? "d-none" : "")
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <Col className="col-12">
                    <Row
                      className="no-gutters"
                      onClick={(e) =>
                        window.open(
                          "https://www.google.com/maps/place/2471+Chemin+de+Baldwin+Mills-Barnston,+Coaticook,+QC+J1A+2S4/@45.0503538,-71.9162614,15z/data=!4m5!3m4!1s0x4cb66094f4d5db23:0xa64b6bd09bf43faa!8m2!3d45.0475497!4d-71.9093772",
                          "_blank"
                        )
                      }
                    >
                      <Col className="modal-box p-4 text-left col-lg-9">
                        <Row className="align-items-center">
                          <Col className="flex-grow-0 flex-basis-init pl-md-5 w-auto">
                            <img src={pin} alt="Map" />
                          </Col>
                          <Col>
                            <h2>
                              2471 Chemin de Baldwin Mills-Barnston, Coaticook,
                              QC J1A 2S4
                            </h2>
                          </Col>
                        </Row>
                      </Col>
                      <Col className="col-3 map-col"></Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
        </ScrollContainer>
      </React.Fragment>
    );
  }
}

export default withRouter(withTranslation()(GoodVisit));
