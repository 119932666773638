import {
  incrementStage,
  postSaveInformation,
  resetStates,
} from "../actions/team";

import GoodVisit from "./goodVisitComponent";
import React from "react";
import { connect } from "react-redux";

class GoodVisitContainer extends React.Component {
  render() {
    return <GoodVisit {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      adultsCount,
      email,
      toddlersCount,
      kidsCount,
      teamName,
      selectedFish,
      stage,
      language,
      playersNamesAges,
      env,
    },
  }) => ({
    adultsCount,
    email,
    toddlersCount,
    kidsCount,
    teamName,
    selectedFish,
    stage,
    language,
    playersNamesAges,
    env,
  }),
  {
    postSaveInformation,
    resetStates,
    incrementStage,
  }
)(GoodVisitContainer);
