import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import GoodVisit from "./goodVisitContainer";
import Modalities from "./modalitiesContainer";
import Players from "./playersContainer";
import Registration from "./registrationContainer";
import Species from "./speciesContainer";
import Welcome from "./welcomeContainer";
import Mission from "./missionContainer";
import bgVid from "../assets/vid/bg.mp4";
import clickAudio from "../assets/audio/Click.mp3";
import { withRouter } from "react-router-dom";

class Main extends Component {
  playSound(e) {
    console.log(e.target);
    if (
      e.target.tagName === "BUTTON" ||
      e.target.classList.contains("cursor-pointer") ||
      e.target.classList.contains("arrow") ||
      e.target.classList.contains("fish-model-viewer-small")       
    ) {
      this.clickAudio.play();
    }
  }
  render() {
    let subscription;
    switch (this.props.stage) {
      default:
        subscription = <Welcome />;
        break;
      case 1:
        subscription = <Mission />;
        break;
      case 2:
        subscription = <Registration />;
        break;
      case 3:
        subscription = <Players />;
        break;
      case 4:
        subscription = <Species />;
        break;
      case 5:
        subscription = <Modalities />;
        break;
      case 6:
        subscription = <GoodVisit />;
        break;
    }

    return (
      <React.Fragment>
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <audio
          ref={(x) => {
            this.clickAudio = x;
          }}
        >
          <source src={clickAudio} type="audio/mpeg"></source>
        </audio>
        <Row
          className="Main flex-column flex-nowrap m-0 w-100"
          onClick={(e) => this.playSound(e)}
        >
          <Col className="d-block d-md-flex align-content-between flex-wrap no-gutters welcome wrapper">
            {subscription}
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(Main);
