import "react-table/react-table.css";

import React, { Component } from "react";
import { ToggleButtonGroup, ToggleButton, Button } from "react-bootstrap";

import Websocket from "react-websocket";
import { withRouter } from "react-router-dom";

class Light {
  constructor(color, state) {
    this.color = color;
    this.state = state;
  }
}

class SocketExteriorGame extends Component {
  state = {};
  getBack() {
    const to = "/user";
    this.props.history.push(to);
  }

  handleOpen() {
    this.sendMessage({ id: this.props.id });
  }

  sendLapDetected(){
    console.log("sendLapDetected");
    this.sendMessage({ id: this.props.id, to:"Big_Swirl", msg:  JSON.stringify({ "command" : "lapDetected" }) });
  }

  sendJumpDetected(intensity){
    console.log("sendJumpDetected" + intensity);
    this.sendMessage({ id: this.props.id, to:"Jump_Frog", msg:  JSON.stringify({ "command" : "jumpDetected", "data" : intensity }) });
  }

  sendButtonPressed(btnId){
    console.log("sendButtonPressed" + btnId);
    this.sendMessage({ id: this.props.id, to:"Great_Stream", msg:  JSON.stringify({ "command" : "buttonPressed", "data" : "TWIST_BUTTON_" + btnId }) });
  }

  sendRFIDScanDetected(order){
    this.sendMessage({ id: this.props.id, to:"Fish_Pass", msg:  JSON.stringify({ "command" : "rfidScanDetected", "data" : { "id" : "PASS_RFID", "index" : order, "code":this.RFIDArray[this.RFIDIndex] } }) });
  }

  ButtonsRFID = ["Omble FR", "Dore FR", "Chevalier EN", "Bar EN"];
  RFIDArray = ["OMBLEFR", "DOREJAUNEFR", "CHEVALIEREN", "BAREN"];
  RFIDIndex = 0;
  updateIndexRFID(selectedIndex) {
    this.RFIDIndex = selectedIndex;
    this.forceUpdate();
  }


  SetLights(lightStates){
    console.debug("setting " + + " lights");
    for (var i = 0; i < lightStates.length; i++)
      this.SetOneLight(lightStates[i], i);
    this.forceUpdate();
  }

  SetOneLight(light, index) {
    index = light.id.substring(light.id.lastIndexOf("_") + 1);
    let LightList = this.GreatCurrentlights;
    if(!light.id.includes("TWIST"))
    LightList = this.FishPasslights;

    if(light.state)
      LightList[index] = new Light(light.color, true);
    else
      LightList[index] = new Light("black", false);
  }

  handleData(data) {
    data = JSON.parse(data);

    switch (data.command) {
      case "Error":
        console.log(data.data);
        break;
      case "Connected":
        this.connected(data.data);
        break;
      case "Disconnected":
        this.disconnected(data.data);
        break;
      case "playSound":
        console.log(data);
        this.sendMessage({ id: this.props.id, to:data.id, msg:  JSON.stringify({ "command" : "result", "data" : "OK" }) });
        break;
      case "setLightStates":
        console.log(data);
        this.SetLights(data.data);
        break;
      default:
        console.log(data);
        break;
    }
  }

  sendMessage(message) {
    console.log(message)
    if (typeof message === "object") {
      message = JSON.stringify(message);
    }
    this.refWebSocket.sendMessage(message);
  }
  
  connected(id) {
    let state = { ...this.state };
    console.log(id + " connected");
    state[id] = "Connect";
    this.setState(state);
  }

  disconnected(id) {
    let state = { ...this.state };
    console.log(id + " disconnected");

    state[id] = "Disconnect";
    this.setState(state);
  }

  handleButton(){
    this[this.props.call]();
  }

  GreatCurrentlights = new Array(12).fill(new Light("blue"));

  FishPasslights = new Array(6).fill(new Light("green"));

  DrawLightGreatStream(light, order)
  {
      return <Button onClick={this.sendButtonPressed.bind(this, order)} style={{backgroundColor:light.color,color:"white",margin:10,padding:15}} key={order}>{order + 1}</Button>
  }

  DrawLightFishPass(light, order)
  {
    return <Button onClick={this.sendRFIDScanDetected.bind(this, order)} style={{backgroundColor:light.color,color:"white",margin:10,padding:15}} key={order + 15}>{order + 1}</Button>
  }

  DrawButtonRFID(PlayerName, order)
  {
    return <ToggleButton value={order} onClick={this.updateIndexRFID.bind(this, order)} key={order + 25}>{PlayerName}</ToggleButton>
  }

  render() {
    let socketURL = process.env.REACT_APP_API_URL.replace("http", "ws");
    return (
      <React.Fragment>
        <div>
          <h3 style={{paddingTop:10, paddingLeft:10}}>Grand remous</h3>
          <Button style={{margin:10,padding:10}} onClick={this.sendLapDetected.bind(this)}>Envoyer un tour !</Button>
        </div>
        <div>
          <h3 style={{paddingTop:10, paddingLeft:10}}>Saute grenouille</h3>
          <Button style={{margin:10,padding:10}} onClick={this.sendJumpDetected.bind(this, 0)}>Jump 0</Button>
          <Button style={{margin:10,padding:10}} onClick={this.sendJumpDetected.bind(this, 1)}>Jump 1</Button>
          <Button style={{margin:10,padding:10}} onClick={this.sendJumpDetected.bind(this, 2)}>Jump 2</Button>
        </div>
        <div>
          <h3 style={{paddingTop:10, paddingLeft:10}}>Grand courant</h3>
          {this.GreatCurrentlights.map(this.DrawLightGreatStream.bind(this))}
        </div>
        <div>
          <h3 style={{paddingTop:10, paddingLeft:10}}>Passe à poissons</h3>
          <ToggleButtonGroup type="radio" name="options" defaultValue={this.RFIDIndex} style={{padding:10}}>
            {this.ButtonsRFID.map(this.DrawButtonRFID.bind(this))}
          </ToggleButtonGroup>
          {this.FishPasslights.map(this.DrawLightFishPass.bind(this))}
        </div>
        <Websocket
          url={socketURL + "/socket"}
          onMessage={this.handleData.bind(this)}
          reconnect={true}
          onOpen={this.handleOpen.bind(this)}
          ref={Websocket => {
            this.refWebSocket = Websocket;
          }}
        />
      </React.Fragment>
    );
  }
}

export default withRouter(SocketExteriorGame);
