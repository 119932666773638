import "react-table/react-table.css";

import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import Header from "./headerContainer";
import Modal from "../utilitiesComponents/modalComponent";
import Moment from "moment";
import ReactTable from "react-table";
import Sidebar from "./teamListSidebarComponent";
import TableSpinner from "../utilitiesComponents/tableSpinnerComponent";
import bgVid from "../assets/vid/bg.mp4";
import chevron from "../assets/img/Chevron.png";
import trash from "../assets/img/Trash.svg";
import { withRouter } from "react-router-dom";

class TeamList extends Component {
  timer = "";
  constructor(props) {
    super(props);
    this.state = {
      teamList: [{}],
      count: 1,
      loading: true,
      query: null,
      selected: null,
      filter: null,
      displayDeleteSelectionConfirmation: false,
      sort: null,
      desc: null,
      date: new Date().toISOString().split("T")[0],
      page: 1,
    };
    this.fetchDataNextAndPrevious = this.fetchDataNextAndPrevious.bind(this);
    this.handleFilters = this.handleFilters.bind(this);
    this.handleQuery = this.handleQuery.bind(this);
    this.handleDate = this.handleDate.bind(this);
  }

  handleQuery(value) {
    this.setState({ ...this.state, query: value, page: 1 });
  }

  fetchData() {
    //console.log(this.state.query);
    this.props.fetchTeamList(
      this.state.pageSize,
      this.state.page,
      this.state.filter,
      this.state.query,
      this.state.sort,
      this.state.desc,
      this.state.query == "" || this.state.query == null ? this.state.date : null
    );
  }

  fetchDataNextAndPrevious(e) {
    var idDesc = e.sorted[0];
    var sort = null;
    var desc = null;
    if (idDesc) {
      if (idDesc.id) sort = idDesc.id;
      if (typeof idDesc.desc) desc = idDesc.desc;
    }

    this.setState(
      {
        ...this.state,
        sort: sort,
        desc: desc,
        pageSize: e.pageSize,
        page: e.page + 1,
      },
      () => {
        this.fetchData();
      }
    );
  }

  handleMarkAsCompleted = (teamId) => {
    this.props.markAsCompleted(teamId);
  };

  handleDeleteSelection() {
    this.setState({ ...this.state, displayDeleteSelectionConfirmation: true });
  }

  async deleteSelection() {
    if (this.state.selected != null) {
      const id = this.props.teamList[this.state.selected].id;
      await this.props.deleteTeam(id);
      this.setState(
        { ...this.state, page: 1, displayDeleteSelectionConfirmation: false },
        () => {
          this.fetchData();
        }
      );
    }
  }

  handleFilters(filter) {
    this.setState({ ...this.state, filter: filter, page: 1 });
  }

  handleDate(date) {
    this.setState({ ...this.state, date: date });
  }

  reset() {
    this.setState(
      {
        ...this.state,
        filter: null,
        query: null,
        page: 1,
        date: new Date().toISOString().split("T")[0],
      },
      () => {
        this.fetchData();
      }
    );
  }

  addTeam() {
    const to = "/addTeam";
    this.props.history.push(to);
  }

  handleTeam(id) {
    const to = `/teams/${id}`;
    this.props.history.push(to);
  }

  timer = "";
  isClicked = false;
  handleClick() {
    this.isClicked = !this.isClicked;
    setTimeout(() => {
      if (this.isClicked) {
        this.isClicked = false;
      }
    }, 300);
  }

  bodyComponent(tableState) {
    return <div className="rt-tbody"> {tableState.children} </div>;
  }

  render() {
    var data = this.props.teamList;
    data = data.map((row) => {
      return row;
    });
    var self = this;

    var page = Math.ceil(this.props.count / this.state.pageSize);

    return (
      <React.Fragment>
        <video className="video-bg" autoPlay loop muted>
          <source src={bgVid} type="video/mp4" />
        </video>
        <Modal
          title="ATTENTION"
          text="Êtes vous sur de vouloir supprimer cette équipe ?"
          type="yesno"
          icon="warning"
          headerTitle="Supprimer"
          display={this.state.displayDeleteSelectionConfirmation}
          onClose={(e) =>
            this.setState({
              ...this.state,
              displayDeleteSelectionConfirmation: false,
            })
          }
          onYes={(e) => this.deleteSelection()}
          onNo={(e) =>
            this.setState({
              ...this.state,
              displayDeleteSelectionConfirmation: false,
            })
          }
        />

        <Header activePage="teamsList" />
        <Row className="teamsList flex-fill wrapper mt-5 ml-5 mr-5 mb-0">
          <Col className="d-flex col-sm-5 col-md-4 col-lg-3 mr-5">
            <Sidebar
              id={this.props.id}
              handleQuery={this.handleQuery}
              handleFilters={this.handleFilters}
              handleDate={this.handleDate}
              fetchData={(e) => this.fetchData()}
              reset={(e) => this.reset()}
            />
          </Col>
          <Col className="d-flex col-sm-7 col-md zIndex100">
            <Row className="flex-grow-1 mt-2">
              <Col className="d-flex flex-column  col-12">
                <Row className="mb-5">
                  <Col>
                    <Row className="d-flex">
                      <Col className="flex-grow-0 flex-basis-init w-auto align-self-center">
                        <h6>Liste des équipes</h6>
                        <p className="smaller active-text xxs-size">
                          {this.props.count} équipes
                        </p>
                      </Col>
                      <Col className="d-flex justify-content-end"></Col>
                    </Row>
                  </Col>
                  <Col className="text-right">
                    <button
                      className="addTeamBtn idle-button small-button ml-3"
                      onClick={(e) => this.addTeam()}
                    >
                      Ajouter une équipe
                    </button>
                  </Col>
                </Row>
                <ReactTable
                  TbodyComponent={(e) => this.bodyComponent(e)}
                  pages={page}
                  showPageSizeOptions={false}
                  minRows={0}
                  page={this.state.page - 1}
                  data={data}
                  // Text
                  previousText= 'Précédent'
                  nextText= 'Suivant'
                  loading={this.props.isTeamListLoading}
                  LoadingComponent={TableSpinner}
                  noDataText= 'Aucune donnée trouvée'
                  pageText= 'Page'
                  ofText= 'de'
                  rowsText= 'lignes'
                  columns={[
                    {
                      Header: "# Commande",
                      id: "orderIDs",
                      maxWidth: 90,
                      Cell: (row) => {
                        return (
                          <Row>
                            <Col>
                              {row.original.ref_no}
                            </Col>
                          </Row>
                        );
                      },
                    },
                    {
                      Header: "Prêt à jouer",
                      id: "state",
                      sortable: false,
                      maxWidth: 100,
                      Cell: (row) => (
                        <Row className="d-flex col-sm-12 justify-self-center">
                          <Col>
                            {row.row._original.status === "completed" ? (
                              <i className="check active-text "></i>
                            ) : (
                              ""
                            )}
                          </Col>
                        </Row>
                      ),
                    },
                    {
                      Header: "Nom d'équipe",
                      id: "name",
                      maxWidth: 350,
                      Cell: (row) => {
                        return (
                          <Row>
                            <Col className="d-flex justify-content-center">
                              {row.original.name}
                            </Col>
                          </Row>
                        );
                      },
                    },
                    {
                      Header: "Courriel",
                      id: "email",
                      maxWidth: 350,
                      Cell: (row) => {
                        return (
                          <Row>
                            <Col className="d-flex justify-content-center">
                              {row.original.email}
                            </Col>
                          </Row>
                        );
                      },
                    },
                    {
                      Header: "Nb Joueur",
                      id: "playersCount",
                      maxWidth: 90,
                      Cell: (row) => {
                        return (
                          <Row>
                            <Col>
                              {row.original.kids_count +
                                row.original.adults_count +
                                row.original.toddlers_count}
                            </Col>
                          </Row>
                        );
                      },
                    },
                    {
                      Header: "Date de la visite",
                      accessor: "registrationDate",
                      Cell: (row) => {
                        return (
                          <React.Fragment>
                            <Row className="flex-grow-1 flex-nowrap no-gutters">
                              <Col className="align-self-center">
                                {Moment.utc(
                                  row.original.registration_date
                                ).format("Y-MM-DD HH:mm:ss")}
                              </Col>
                              <Col className="d-flex justify-content-end">
                                <img
                                  className="cursor-pointer row-delete"
                                  width={21.85}
                                  height={23}
                                  alt="Delete"
                                  src={trash}
                                  onClick={(e) => {
                                    setTimeout(() => {
                                      this.handleDeleteSelection();
                                    }, 1);
                                  }}
                                />
                              </Col>
                              <Col className="d-flex justify-content-end mr-4">
                                <img
                                  className="cursor-pointer"
                                  width={13}
                                  height={23}
                                  alt="Details"
                                  src={chevron}
                                  onClick={(e) =>
                                    this.handleTeam(row.original.id)
                                  }
                                />
                              </Col>
                            </Row>
                          </React.Fragment>
                        );
                      },
                    },
                  ]}
                  className="data-table mt-3 mb-3"
                  defaultPageSize={20}
                  manual
                  onFetchData={(e) => this.fetchDataNextAndPrevious(e)}
                  getTrProps={(_state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                      return {
                        onClick: (e) => {
                          this.handleClick();
                          if (this.isClicked)
                            this.setState({
                              ...this.state,
                              selected: rowInfo.index,
                            });
                          else {
                            const to = `/teams/${rowInfo.original.id}`;
                            self.props.history.push(to);
                          }
                        },
                        className:
                          rowInfo.index === this.state.selected
                            ? "selected-row"
                            : "",
                      };
                    } else {
                      return {};
                    }
                  }}
                ></ReactTable>
              </Col>
            </Row>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withRouter(TeamList);
