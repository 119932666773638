import "bootstrap/dist/css/bootstrap.css";
import "./styles/mainBundle.scss";

import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import AddTeamPage from "./pages/AddTeamPage";
import LoginPage from "./pages/Login";
import MainPage from "./pages/Main";
import SettingPage from "./pages/SettingPage";
import TeamPage from "./pages/TeamPage";
import UserPage from "./pages/UserPage";
import NoTicketPage from "./pages/NoTicketPage";

import ExteriorGamePage from "./pages/ExteriorGamePage";

class App extends Component {
  render() {
    return (
      <Switch>
        <Route path="/login" component={LoginPage} exact/>
        <Route path="/setting" component={SettingPage} exact/>
        <Route path="/exteriorgame" component={ExteriorGamePage} exact/>
        <Route path="/user" component={UserPage} exact/>
        <Route path="/addTeam" component={AddTeamPage} exact/>
        <Route path="/teams/:id" component={TeamPage} exact/>
        <Route path="/register" component={MainPage} exact/>         
        <Route path="/registerOnsite" component={MainPage} exact/>                
        <Route path="/0" component={MainPage} exact />        
        <Route path="/1" component={MainPage} exact />        
        <Route path="/2" component={MainPage} exact />        
        <Route path="/3" component={MainPage} exact />        
        <Route path="/4" component={MainPage} exact />        
        <Route path="/5" component={MainPage} exact />        
        <Route path="/6" component={MainPage} exact/>        
        <Route path="/" component={NoTicketPage} /> 
      </Switch>
    );
  }
}

export default App;
