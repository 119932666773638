import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import { withRouter } from "react-router-dom";
import { withTranslation } from "react-i18next";

// Idle timer - User inactivity
import IdleTimer from 'react-idle-timer'

class Footer extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.handleOnAction = this.handleOnAction.bind(this)
    this.handleOnActive = this.handleOnActive.bind(this)
    this.handleOnIdle = this.handleOnIdle.bind(this)
  }
  
  handleCancel = () => {
    this.props.resetStates();
    this.props.history.push(`/${0}`);
  };

  handlePrevious = () => {
    var previousStage = this.props.stage - 1;
    previousStage = previousStage < 0 ? 0 : previousStage;
    this.props.history.push(`/${previousStage}`);
    this.props.decrementStage(this.props.stage);
  };

  render() {
    const { t, continueCallback } = this.props;
    let continueCls = "disable-button";

    if (this.props.formValidated) {
      continueCls = "active-btn-gradient";
    }
    let continueButton = (
      <button
        label="Continue"
        className={continueCls + " medium-button"}
        title="Continue"
        type="submit"
        onClick={continueCallback}
      >
        <span>{t("Next")}</span>
        <i className="arrow arrow-right"></i>
      </button>
    );
    if (this.props.stage === 5) {
      let cls = "active-btn-gradient";

      continueButton = (
        <button
          label="AcceptModalities"
          className={cls + " medium-button"}
          title="AcceptModalities"
          type="submit"
          onClick={continueCallback}
        >
          <span>{t("Finish")}</span>
          <i className="arrow arrow-right"></i>
        </button>
      );
    }

    

    return (
      <Row
        className={"btnWrapper w-100 mb-5 mt-auto fixed-bottom " + this.props.className}
      >
        <IdleTimer
          ref={ref => { this.idleTimer = ref }}
          // User inactivity : The timer is set to 2 minutes 
          timeout={1000 * 60 * 2}
          onActive={this.handleOnActive}
          onIdle={this.handleOnIdle}
          onAction={this.handleOnAction}
          debounce={250}
        />
        <Col>
          <Row>
            <Col className="mt-4 mt-md-0">
              <Row className="flex-nowrap justify-content-center ">
                <Col className="flex-grow-0 flex-basis-init p-0 w-auto">
                  <button
                    label="Previous"
                    className="inactive-btn-gradient medium-button mr-4"
                    title="Previous"
                    type="button"
                    onClick={this.handlePrevious}
                  >
                    <i className="arrow arrow-left"></i>
                    <span>{t("Previous")}</span>
                  </button>
                  {continueButton}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    );
    
  }
  handleOnAction (event) {
  }

  handleOnActive (event) {
  }

  handleOnIdle (event) {
    this.handleCancel()
  }
}
export default withRouter(withTranslation()(Footer));
