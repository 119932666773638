import {
  incrementStage,
  setAdultsCount,
  setKidsCount,
  setPlayersNamesAges,
  setToddlersCount,
} from "../actions/team";

import Players from "./playersComponent";
import React from "react";
import { connect } from "react-redux";

class PlayersContainer extends React.Component {
  render() {
    return <Players {...this.props} />;
  }
}

export default connect(
  ({
    teamReducer: {
      stage,
      players,
      kidsCount,
      adultsCount,
      toddlersCount,
      playersNamesAges,
      automated,
    },
  }) => ({
    stage,
    players,
    kidsCount,
    adultsCount,
    toddlersCount,
    playersNamesAges,
    automated,
  }),
  {
    incrementStage,
    setPlayersNamesAges,
    setAdultsCount,
    setKidsCount,
    setToddlersCount,
  }
)(PlayersContainer);
