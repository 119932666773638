import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

import FishViewerComponent from "./fishModelViewerSmallComponent";
import { withTranslation } from "react-i18next";
import yes from "../assets/img/Yes.png";

class FishComponent extends Component {
  handleShowFish(id) {
    this.props.setFishStage(id);
  }

  render() {
    let selected = <div />;
    if (this.props.selectedFish === this.props.fishId) {
      selected = yes;
    }
    return (
      <React.Fragment>
        <Row>
          <Col className="item-box-check mr-5 mr-md-0">
            {typeof selected != "object" ? (
              <i className="check"></i>
            ) : (
              <React.Fragment />
            )}
          </Col>
        </Row>
        <Row
          onClick={() => this.handleShowFish(this.props.fishId)}
        >
          <Col className="item-box fish-box align-items-center ml-3 mr-5 mr-md-0 ">
            <div className="item-box-content ml-5">
              <Row>
                <Col className="text-center mt-2">
                  <p className="smaller">{this.props.fishName}</p>
                </Col>
              </Row>
              <Row>
                <Col className="text-center">
                  <h2>{this.props.fishCharacteristic}</h2>
                </Col>
              </Row>
              <Row className="m-auto">
                <Col>
                  <FishViewerComponent
                    src={this.props.fishesImage[this.props.fishId]}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default withTranslation()(FishComponent);
