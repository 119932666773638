import { Col, Row } from "react-bootstrap";
import React, { Component } from "react";

class ProgressBar extends Component {
  render() {
    const percent = (this.props.now / this.props.max) * 100;
    const colCount = Math.round((percent * 7) / 100);
    let cols = [];
    for (var index = 0; index < colCount; index++) {
      cols.push(<Col key={index} className="mr-1 progress-bar-active"></Col>);
    }
    for (index; index < 7; index++) {
      cols.push(<Col key={index} className="mr-1"></Col>);
    }
    return (
      <React.Fragment>
        <Row className={"progress-bar-row no-gutters flex-nowrap " + this.props.className}>
          {cols}
        </Row>
      </React.Fragment>
    );
  }
}

export default ProgressBar;
