import {
  incrementStage,
  setStage
} from "../actions/team";

import Main from "./mainComponent";
import React from "react";
import { connect } from "react-redux";

class MainContainer extends React.Component {
  render() {
    return <Main {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage, fishStage } }) => ({
    stage,
    fishStage
  }),
  {
    incrementStage,
    setStage
  }
)(MainContainer);
