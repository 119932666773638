import Mission from "./missionComponent";
import React from "react";
import { connect } from "react-redux";
import { incrementStage } from "../actions/team";

class MissionContainer extends React.Component {
  render() {
    return <Mission {...this.props} />;
  }
}

export default connect(
  ({ teamReducer: { stage } }) => ({
    stage
  }),
  { incrementStage }
)(MissionContainer);
