import * as service from "../api";

import { createAction, createReducer } from "redux-act";

const defaultState = {
  sallInteractivePlayers: []
};

const setSallInteractivePlayersInfo = createAction();

export const fetchSallInteractivePlayersInfo = params => async dispatch => {
  try {
    if (!params.length) {
      dispatch(setSallInteractivePlayersInfo({ data: { players: [] } }));
      return;
    }
    const data = await service.fetchSallInteractivePlayersInfo(params);
    dispatch(setSallInteractivePlayersInfo(data));
  } catch (e) {
    console.log(e);
  }
};

const settingReducer = createReducer(
  {
    [setSallInteractivePlayersInfo]: (state, data) => {
      return { ...state, sallInteractivePlayers: data.data.players };
    }
  },
  defaultState
);

export default settingReducer;
