import MainBundle from "src/modules/mainBundle";
import React from "react";
import { Route } from "react-router-dom";

class Routes extends React.Component {
  render() {
    return <Route path="/" component={MainBundle} />;
  }
}

export default Routes;
